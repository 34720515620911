import {
    AppMode,
    TenantAllowedBarrierGateVehicleIdentification,
    TenantPaymentMode,
} from 'dg-web-shared/model/TenantEnums.ts';

export function barrierGateIdentificationText(
    allowedBarrierGateIdentification: TenantAllowedBarrierGateVehicleIdentification,
) {
    switch (allowedBarrierGateIdentification) {
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
            return {
                de: 'LPR mit QR',
                fr: 'LPR avec QR',
                it: 'LPR con QR',
                en: 'LPR with QR',
            };
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
            return {
                de: 'LPR mit Badge',
                fr: 'LPR avec badge',
                it: 'LPR con badge',
                en: 'LPR with badge',
            };
        case TenantAllowedBarrierGateVehicleIdentification.BADGE:
            return {
                de: 'Badge',
                fr: 'badge',
                it: 'badge',
                en: 'badge',
            };
    }
}

export function appAndPaymentModeText(
    appMode: AppMode,
    paymentMode: TenantPaymentMode,
) {
    if (appMode == AppMode.NONE) {
        return {
            de: 'Ohne App',
            fr: 'Sans app',
            it: 'Senza app',
            en: 'No app',
        };
    }
    switch (paymentMode) {
        case TenantPaymentMode.COUNTER:
            return {
                de: 'App (ohne E-Commerce)',
                fr: 'App (sans e-commerce)',
                it: 'App (senza e-commerce)',
                en: 'App (without e-commerce)',
            };
        case TenantPaymentMode.DIGITAL_PURCHASE:
            return {
                de: 'App (mit E-Commerce)',
                fr: 'App (avec e-commerce)',
                it: 'App (con e-commerce)',
                en: 'App (with e-commerce)',
            };
    }
}
