import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { RestrictedComponent } from '../app/components/RestrictedComponent.tsx';
import { OperatorAppRoutes } from '../app/config/OperatorRoutingConfig.tsx';
import { CameraAlt } from '@mui/icons-material';
import { getBridge } from '../app/components/Native.tsx';
import { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import { Outlet } from 'react-router-dom';

export function CloudConnectorRecoder() {
    const [openScanNotAvailableDialog, setOpenScanNotAvaialbleDialog] =
        useState(false);

    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Umkodierung"
                    fr="Recodage"
                    it="Ricodifica"
                    en="Recoding"
                />
            }
            backTo={'..'}
        >
            <RestrictedComponent route={OperatorAppRoutes.CloudConnectorRecode}>
                <Box sx={{ padding: theme => theme.spacing(3) }}>
                    <BaseCard
                        onClick={() => {
                            const bridge = getBridge();
                            if (bridge && bridge.scanQRCode) {
                                bridge.scanQRCode();
                            } else {
                                setOpenScanNotAvaialbleDialog(true);
                            }
                        }}
                    >
                        <Typography
                            component="div"
                            flexGrow={1}
                            textTransform="uppercase"
                            fontWeight="bold"
                        >
                            <Localized
                                de="Umkodierung"
                                fr="Recodage"
                                it="Ricodifica"
                                en="Recoding"
                            />
                        </Typography>
                        <CameraAlt />
                    </BaseCard>
                </Box>
            </RestrictedComponent>

            <ScanNotAvailableDialog
                open={openScanNotAvailableDialog}
                onClose={() => setOpenScanNotAvaialbleDialog(false)}
            />

            <Outlet />
        </ParkingPortalLayoutWithHeader>
    );
}

function ScanNotAvailableDialog({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) {
    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle
                sx={{
                    backgroundColor: theme => theme.palette.error.main,
                    color: theme => theme.palette.white.main,
                }}
            >
                <Localized
                    de="Scannen nicht möglich"
                    fr="Numérisation impossible"
                    it="Scansione impossibile"
                    en="Scanning not possible"
                />
            </DialogTitle>
            <DialogContent sx={{ marginTop: '15px' }}>
                <Localized
                    de="Stellen Sie sicher, dass Sie die aktuelle Version der App verwenden."
                    fr="Assurez-vous que vous utilisez la dernière version de l'application."
                    it="Assicurati di utilizzare la versione più recente dell'app."
                    en="Make sure you are using the latest version of the app."
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    <Localized de="OK" fr="OK" it="OK" en="OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function BaseCard({
    onClick,
    children,
}: {
    onClick: () => void;
    children: React.ReactNode;
}) {
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={theme => ({
                    padding: theme.spacing(2),
                    paddingRight: theme.spacing(3),
                    minHeight: theme.spacing(10),
                    backgroundColor: theme.palette.blue.light,
                })}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {children}
                </Stack>
            </CardActionArea>
        </Card>
    );
}
