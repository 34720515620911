import {
    Alert,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    ServerRequestState,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { ParkingaboUser } from './OperatorParkingaboUsersForm.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { DateTime } from 'luxon';
import { ProductsAddTemplateItem } from './OperatorParkingaboUsersProductsAdd.tsx';
import { Message } from 'dg-web-shared/lib/Localized.ts';
import {
    determineProductStatus,
    OperatorProductStatusIcon,
} from '../products/OperatorProductState.tsx';
import {
    generatePath,
    Outlet,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { OperatorProductDetailModal } from '../products/OperatorProductDetailModal.tsx';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import {
    LastUpdateTimestampAndRefresh,
    LastUpdateTimestampAndRefreshAsyncLoadedSection,
} from '../ui/LastUpdateTimestampAndRefresh.tsx';
import {
    DetailHeader,
    DetailTabs,
    ParkingaboUserInfoTab,
    useParkingaboUserDetailOutletContext,
    UserDeailsSearchBar,
} from './OperatorParkingaboUsersDetail.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { TenantPaymentMode } from 'dg-web-shared/model/TenantEnums.ts';
import { ResultLimitAlert } from '../shared-mui-components/ListMessages.tsx';
import { CustomerTenantState } from 'dg-web-shared/model/CustomerTenantState.ts';

export interface ParkingaboCustomerProductsResult {
    results: ParkingaboProductWithPossibleStorno[];
    eof: boolean;
}

interface ParkingaboProductWithPossibleStorno {
    contractId: number;
    contractTemplateId: number;
    name: Message;
    createdAt: string;
    originalValidFrom: string;
    originalValidTo?: string;
    isStorno: boolean;
}

export function OperatorParkingaboUsersProducts() {
    const { parkingaboUser, refetchParkingaboUser } =
        useParkingaboUserDetailOutletContext();
    const [templateState] = useServerFetch<
        ProductsAddTemplateItem[],
        { tenantId: string }
    >(
        ({ tenantId }) => ({
            url: `/ui-api/operator-account/parkingabo/product-templates/${tenantId}`,
        }),
        { tenantId: parkingaboUser.tenant.tenantId },
    );

    const [productsState, refetchProducts] = useServerFetch<
        ParkingaboCustomerProductsResult,
        { customerNr: string },
        null
    >(
        context => ({
            url: `/ui-api/operator-account/parkingabo/customer/${context.customerNr}/products`,
        }),
        { customerNr: parkingaboUser.customerNr },
    );

    const operatorContext = useOperatorContext();
    const productData = productsState.data?.results;
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const queryFilters = makeSearchQueryWithFilters(searchText, activeFilters);
    const navigate = useNavigate();

    return (
        <OperatorRoutedModalContent
            header={<DetailHeader />}
            bar={
                <DetailTabs
                    customerNr={parkingaboUser.customerNr}
                    parkingaboUserInfoTab={ParkingaboUserInfoTab.PRODUCT}
                    tenantHasDigitalPurchase={
                        parkingaboUser.tenant.paymentMode ===
                        TenantPaymentMode.DIGITAL_PURCHASE
                    }
                />
            }
            style={{ padding: '0px 0px' }}
            body={
                <OperatorAsyncLoadedSection
                    requestState={templateState}
                    render={productTemplates => {
                        const purchasableProductTemplates = productData
                            ? productTemplates.filter(template =>
                                  operatorContext.currentLogin.allowedProductTypes.includes(
                                      template.contractTemplateId,
                                  ),
                              )
                            : [];
                        return (
                            <>
                                <UserDeailsSearchBar
                                    button={
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                navigate(
                                                    `${generatePath(
                                                        '/parkingabo/users/:customerNr/products/add',
                                                        {
                                                            customerNr:
                                                                parkingaboUser.customerNr,
                                                        },
                                                    )}${queryFilters}`,
                                                )
                                            }
                                            disabled={
                                                parkingaboUser.state !==
                                                    CustomerTenantState.ACTIVE ||
                                                purchasableProductTemplates.length <
                                                    1
                                            }
                                        >
                                            <Localized
                                                de="Hinzufügen"
                                                fr="Ajouter"
                                                it="Aggiungi"
                                                en="Add"
                                            />
                                        </Button>
                                    }
                                    refreshButton={
                                        <LastUpdateTimestampAndRefreshAsyncLoadedSection
                                            requestState={productsState}
                                            onRefresh={refetchProducts}
                                            render={(products, timestamp) => (
                                                <LastUpdateTimestampAndRefresh
                                                    resultsCount={
                                                        products.results.length
                                                    }
                                                    eof={products.eof}
                                                    onRefresh={refetchProducts}
                                                    timestamp={timestamp}
                                                />
                                            )}
                                        />
                                    }
                                />
                                <OperatorParkingaboUsersProductsTable
                                    productsState={productsState}
                                    goToEditProduct={(contractId: string) =>
                                        navigate(
                                            `${generatePath(
                                                '/parkingabo/users/:customerNr/products/:contractId/detail',
                                                {
                                                    customerNr:
                                                        parkingaboUser.customerNr,
                                                    contractId,
                                                },
                                            )}${queryFilters}`,
                                        )
                                    }
                                />

                                <Outlet
                                    context={{
                                        parkingaboUser: parkingaboUser,
                                        purchasableProductTemplates:
                                            purchasableProductTemplates,
                                        refetchProducts: refetchProducts,
                                        refetchParkingaboUser:
                                            refetchParkingaboUser,
                                    }}
                                />
                            </>
                        );
                    }}
                />
            }
        />
    );
}

export type ParkingaboUsersProductsAddContextType = {
    parkingaboUser: ParkingaboUser;
    purchasableProductTemplates: ProductsAddTemplateItem[];
    refetchProducts: () => void;
    refetchParkingaboUser: () => void;
};

export function OperatorParkingaboUserProductDetail() {
    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const { parkingaboUser, refetchProducts } =
        useOutletContext<ParkingaboUsersProductsAddContextType>();

    return (
        <OperatorProductDetailModal
            backUrl={`${generatePath('/parkingabo/users/:customerNr/products', {
                customerNr: parkingaboUser.customerNr,
            })}${makeSearchQueryWithFilters(searchText, activeFilters)}`}
            onProductChange={refetchProducts}
        />
    );
}

interface OperatorParkingaboUsersProductsTableProps {
    productsState: ServerRequestState<ParkingaboCustomerProductsResult, null>;
    goToEditProduct: (contractId: string) => void;
}

function OperatorParkingaboUsersProductsTable({
    productsState,
    goToEditProduct,
}: OperatorParkingaboUsersProductsTableProps): JSX.Element {
    return (
        <OperatorAsyncLoadedSection
            requestState={productsState}
            render={products => {
                if (products.results.length <= 0) {
                    return (
                        <Alert severity="info">
                            <Localized
                                de="Dieser Benutzer hat keine Produkte."
                                fr="Cet utilisateur n'a pas de produits."
                                it="Questo utente non ha prodotti."
                                en="This user has no products."
                            />
                        </Alert>
                    );
                }

                return (
                    <TableContainer
                        sx={{
                            flex: '1 1 0',
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Localized
                                            de="Zustand"
                                            fr="État"
                                            it="Stato"
                                            en="State"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Localized
                                            de="Beschreibung"
                                            fr="Description"
                                            it="Descrizione"
                                            en="Description"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Localized
                                            de="Beginn"
                                            fr="Début"
                                            it="Inizio"
                                            en="Begin"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Localized
                                            de="Ende"
                                            fr="Fin"
                                            it="Fine"
                                            en="End"
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.results.map(product => (
                                    <TableRow
                                        key={product.contractId}
                                        hover
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            goToEditProduct(
                                                product.contractId.toString(),
                                            )
                                        }
                                    >
                                        <TableCell>
                                            <OperatorProductStatusIcon
                                                status={determineProductStatus(
                                                    product.isStorno,
                                                    DateTime.fromISO(
                                                        product.originalValidFrom,
                                                    ),
                                                    product.originalValidTo
                                                        ? DateTime.fromISO(
                                                              product.originalValidTo,
                                                          )
                                                        : null,
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized {...product.name} />
                                        </TableCell>
                                        <TableCell>
                                            {DateTime.fromISO(
                                                product.originalValidFrom,
                                            ).toFormat('dd.MM.yyyy – HH:mm')}
                                        </TableCell>
                                        <TableCell>
                                            {product.originalValidTo
                                                ? DateTime.fromISO(
                                                      product.originalValidTo,
                                                  ).toFormat(
                                                      'dd.MM.yyyy – HH:mm',
                                                  )
                                                : ' - '}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <ResultLimitAlert
                            eof={products.eof}
                            numberOfResults={products.results.length}
                            hasExport={false}
                        />
                    </TableContainer>
                );
            }}
        />
    );
}
