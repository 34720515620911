import { Message } from '../lib/Localized.ts';
import { AppMode } from './TenantEnums.ts';

export const enum CustomerTenantState {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    LOCKED = 'LOCKED',
}

export function getTranslationOfCustomerTenantState(
    state: CustomerTenantState | null | undefined,
    onboardingCompleteTime: string | null | undefined,
    appMode: AppMode,
): Message {
    switch (state) {
        case CustomerTenantState.ACTIVE:
            if (onboardingCompleteTime || appMode === AppMode.NONE) {
                return {
                    de: 'AKTIV',
                    fr: 'ACTIF',
                    it: 'ATTIVO',
                    en: 'ACTIVE',
                };
            }
            return {
                de: 'PENDENT',
                fr: 'EN ATTENTE',
                it: 'IN ATTESA',
                en: 'PENDING',
            };
        case CustomerTenantState.ARCHIVED:
            return {
                de: 'ARCHIVIERT',
                fr: 'ARCHIVÉ',
                it: 'ARCHIVIATO',
                en: 'ARCHIVED',
            };
        case CustomerTenantState.LOCKED:
            return {
                de: 'GESPERRT',
                fr: 'VERROUILLÉ',
                it: 'BLOCCATO',
                en: 'LOCKED',
            };
        case null:
        case undefined:
            return {
                de: '',
                fr: '',
                it: '',
                en: '',
            };
    }
}
