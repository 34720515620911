import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Localized } from '../common/components/Localized.tsx';
import { Box, Stack } from '@mui/material';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import {
    RecodeErrorType,
    TicketRecodeCondition,
    useRecodeTicketOutlet,
} from './CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { ParkingPortalLayoutWithHeader } from '../mobile/layout/ParkingPortalLayoutWithHeader.tsx';
import {
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';

export function CloudConnectorRecodeTicketCondition() {
    const { recodeConditionId } = useParams<{ recodeConditionId: string }>();
    const { recodeConditions } = useRecodeTicketOutlet();
    const recodeCondition = recodeConditions.find(
        recodeCondition =>
            recodeCondition.recodeConditionId === recodeConditionId,
    );
    const isSingleCondition = recodeConditions.length === 1;
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Ticket umkodieren"
                    fr="Recoder le ticket"
                    it="Ricodificare ticket"
                    en="Recode ticket"
                />
            }
            backTo={isSingleCondition ? '../..' : '..'}
        >
            <RecodeConditionBody recodeCondition={recodeCondition} />
        </ParkingPortalLayoutWithHeader>
    );
}

interface RecodeTicketPayload {
    recodeConditionId: string;
    ticketId: string;
}

function RecodeConditionBody({
    recodeCondition,
}: {
    recodeCondition?: TicketRecodeCondition;
}) {
    if (!recodeCondition) {
        return (
            <Navigate
                to={`/mobile/recode/error/${RecodeErrorType.ZONE_NOT_AVAILABLE}`}
            />
        );
    }

    const [recodeTicketState, recodeTicket] = useServerWrite<
        RecodeTicketPayload,
        null,
        RecodeErrorType
    >(payload => ({
        url: `/ui-api/operator-account/cloud-connector/recode/ticket/${payload.ticketId}`,
    }));

    const navigate = useNavigate();
    useServerSuccessEffect(recodeTicketState, () =>
        navigate(`/mobile/recode/success`),
    );
    useServerErrorEffect(recodeTicketState, (_, recodeError) =>
        navigate(`/mobile/recode/error/${recodeError}`),
    );

    const pending = recodeTicketState.status === RequestStatus.PENDING;
    return (
        <Box
            sx={{
                padding: 4,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
            }}
        >
            <Stack spacing={2}>
                <ReadOnlyTextField
                    label={
                        <Localized
                            de="Bedingung"
                            fr="Condition"
                            it="Condizione"
                            en="Condition"
                        />
                    }
                    value={
                        <Localized
                            {...recodeCondition.recodeConditionDescription}
                        />
                    }
                />
                <ReadOnlyTextField
                    label={
                        <Localized
                            de="Ticket"
                            fr="Ticket"
                            it="Ticket"
                            en="Ticket"
                        />
                    }
                    value={recodeCondition.ticketApiString}
                />
                <ReadOnlyTextField
                    label={
                        <Localized de="Zone" fr="Zone" it="Zona" en="Zone" />
                    }
                    value={recodeCondition.zoneDescription}
                />
                <ReadOnlyTextField
                    label={
                        <Localized
                            de="Tarif"
                            fr="Tarif"
                            it="Tariffa"
                            en="Tariff"
                        />
                    }
                    value={recodeCondition.tariffName}
                />
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '48px',
                    fontSize: '16px',
                    marginTop: '10px',
                    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.30)',
                    background: pending
                        ? Colors.disabledButtonGrey
                        : Colors.grassGreen,
                    textTransform: 'uppercase',
                    letterSpacing: '0.8px',
                    color: Colors.white,
                    '&:active': {
                        padding: '2px 0 0 2px',
                        backgroundColor: 'rgba(85, 196, 40, 0.8)',
                    },
                }}
                onClick={
                    !pending
                        ? () =>
                              recodeTicket({
                                  recodeConditionId:
                                      recodeCondition.recodeConditionId,
                                  ticketId: recodeCondition.ticketId,
                              })
                        : undefined
                }
            >
                {pending ? (
                    <PresetLoadingSpinner
                        preset={LoadingSpinnerPresets.EmbedInButton}
                    />
                ) : (
                    <Localized
                        de="Umkodieren"
                        fr="Recoder"
                        it="Ricodifica"
                        en="Recode"
                    />
                )}
            </Box>
        </Box>
    );
}
