import * as Flux from 'dg-web-shared/lib/Flux.tsx';
import { useStore } from 'dg-web-shared/lib/Flux.tsx';
import { FormValidation } from 'dg-web-shared/lib/FormValidation.ts';
import {
    forceDef,
    getOrElse,
    isDefined,
    Maybe,
    thenElse,
} from 'dg-web-shared/lib/MaybeV2.ts';
import { Conditional } from 'dg-web-shared/lib/ReactHelpers.tsx';
import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import { CurrentOperatorLogin } from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import * as CommonOperatorLoginsState from '../../common/state/OperatorLoginsState.ts';
import * as PermitTypeState from '../../common/state/PermitTypeState.ts';
import * as SettingsState from '../../common/state/SettingsState.ts';
import { ButtonSpecialState } from '../../ui/buttons/IconButton.tsx';
import { SingleSelection } from '../../ui/labeled-elements/SingleSelection.tsx';
import { TextField } from '../../ui/labeled-elements/TextField.tsx';
import {
    ConfirmSaveHeader,
    FullSlideIn,
    FullSlideInLeftColumn,
    FullSlideInRightColumn,
    LoaderHeader,
    SlideInBody,
    SlideInHeaderTexts,
    StandardFirstLevelHeader,
} from '../../ui/slidein/Slidein.tsx';
import { Switch, SwitchType } from '../../ui/switches/Switches.tsx';
import {
    closeSlideIn,
    editLogin,
    makeEditPayload,
    makePostPayload,
    newLogin,
} from '../actions/OperatorLoginActions.ts';
import { operatorLoginsListTexts } from '../i18n/OperatorLoginsTexts.ts';
import * as OperatorLoginsState from '../state/OperatorLoginsState.ts';
import { LoginMeta } from './LoginMeta.tsx';
import { SubTitle, Title } from './Titles.tsx';
import { Validation } from './Validation.ts';
import { Localized, useLocalized } from '../../common/components/Localized.tsx';
import { zones } from './ZoneFilterHalfSlideIn.tsx';
import * as MasterDataZonesState from '../../common/state/MasterDataZonesState.ts';
import * as ParkingsState from '../../common/state/ParkingsState.ts';
import { prepareAllowedZones } from './OperatorLoginZoneRestraintSlideIn.tsx';
import { css } from '@emotion/css';
import { ProductType, RecodeCondition } from './OperatorLoginsList.tsx';
import { ApiError } from 'dg-web-shared/dto/ApiError.ts';

const selectState = (store: Flux.Store) => ({
    layout: OperatorLoginsState.Layout.get(store),
    operatorLoginsList: CommonOperatorLoginsState.List.get(store),
    edit: OperatorLoginsState.Edit.get(store),
    editCreateResponse: OperatorLoginsState.EditCreateResponse.get(store),
    settings: new SettingsState.StateSlice(store).state,
    passwordEdit: OperatorLoginsState.PasswordEdit.get(store),
    permitTypes: PermitTypeState.get(store),
    zonesMasterData: MasterDataZonesState.get(store),
    parkings: ParkingsState.get(store),
    operatorData: OperatorDataState.get(store),
    currentLogin: CurrentOperatorLoginState.get(store),
});

interface EditState {
    settings: SettingsState.State;
    layout: OperatorLoginsState.Layout.State;
    edit: OperatorLoginsState.Edit.State;
    editCreateResponse: OperatorLoginsState.EditCreateResponse.State;
    passwordEdit: OperatorLoginsState.PasswordEdit.State;
}

const isFormValid = (
    p: EditState,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
    maxAnonymizeCheckinPublicPermitAfterDays: number,
) =>
    (p.layout.createEnabled &&
        Validation.LoginForm.validateForm({
            edit: p.edit,
            editCreateResponse: p.editCreateResponse,
            settings: p.settings,
            login,
            maxAnonymizeCheckinPublicPermitAfterDays:
                maxAnonymizeCheckinPublicPermitAfterDays,
        }).isValid &&
        Validation.PasswordForm.validateForm(p).isValid) ||
    (!p.layout.createEnabled &&
        Validation.LoginForm.validateForm({
            edit: p.edit,
            editCreateResponse: p.editCreateResponse,
            settings: p.settings,
            login,
            maxAnonymizeCheckinPublicPermitAfterDays:
                maxAnonymizeCheckinPublicPermitAfterDays,
        }).isValid);

export const texts = (p: { settings: SettingsState.State }) =>
    operatorLoginsListTexts[p.settings.language];

function Header({ currentLogin }: { currentLogin: CurrentOperatorLogin }) {
    const { storeState, update } = useStore(selectState);

    const selectedId = storeState.layout.selectedOperatorLogin;

    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );

    if (!login && !storeState.layout.createEnabled) {
        return <LoaderHeader />;
    } else if (storeState.editCreateResponse.pending) {
        return <LoaderHeader />;
    } else if (
        storeState.layout.createEnabled ||
        OperatorLoginsState.Edit.hasChanges(storeState.edit, forceDef(login))
    ) {
        return (
            <ConfirmSaveHeader
                language={storeState.settings.language}
                title={
                    storeState.layout.createEnabled
                        ? texts(storeState).saveNew()
                        : texts(storeState).saveEdit()
                }
                onCancel={() => update(closeSlideIn)}
                onSave={() => {
                    if (
                        isFormValid(
                            storeState,
                            login,
                            currentLogin.maxSoftAnonymizeCheckinPublicPermitAfterDays,
                        )
                    ) {
                        if (storeState.layout.createEnabled) {
                            update(
                                newLogin,

                                makePostPayload(
                                    storeState.edit,
                                    storeState.passwordEdit,
                                    currentLogin.softAnonymizeCheckinPublicPermitAfterDays,
                                ),
                            );
                        } else {
                            update(editLogin, {
                                loginId: forceDef(login).operatorLoginId,
                                payload: makeEditPayload(
                                    storeState.edit,
                                    forceDef(login),
                                ),
                            });
                        }
                    } else {
                        update(store =>
                            OperatorLoginsState.Layout.stateWrite(store, {
                                showLoginErrors: true,
                            }),
                        );
                    }
                }}
                confirmButtonSpecialState={
                    !isFormValid(
                        storeState,
                        login,
                        currentLogin.maxSoftAnonymizeCheckinPublicPermitAfterDays,
                    )
                        ? ButtonSpecialState.DISABLED
                        : null
                }
            />
        );
    } else {
        return (
            <StandardFirstLevelHeader onClose={() => update(closeSlideIn)}>
                <SlideInHeaderTexts
                    subtitle={texts(storeState).activeUsers()}
                    title={CommonOperatorLoginsState.getLoginTitle(
                        forceDef(login),
                    )}
                    hasLeftIcon={false}
                />
            </StandardFirstLevelHeader>
        );
    }
}

interface PermissionSwitchProps {
    label: React.ReactNode;
    editVal: Maybe<boolean>;
    persistedVal: boolean;
    write: (val: boolean) => void;
    disabled: boolean;
}

export const PermissionSwitch = (p: PermissionSwitchProps): JSX.Element => {
    const selectedVal = getOrElse(p.editVal, p.persistedVal);
    return (
        <div
            className={css({
                marginTop: '24px',
            })}
        >
            <Switch
                type={SwitchType.Node}
                label={p.label}
                selected={getOrElse(p.editVal, p.persistedVal)}
                onClick={() => p.write(!selectedVal)}
                disabled={p.disabled}
            />
        </div>
    );
};

interface AllowedPermitTypesProps {
    edit: OperatorLoginsState.Edit.State;
    settings: SettingsState.State;
    permitTypes: PermitTypeState.State;
}

const allowedPermitTypesCount = (
    p: AllowedPermitTypesProps,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
): number | null => {
    if (!login) {
        return thenElse(p.edit.allowedPermitTypes, t => t.length, null);
    } else {
        return thenElse(
            p.edit.allowedPermitTypes,
            ap => ap.length,
            login.allowedPermitTypes.length,
        );
    }
};

const allowedProductTypesCount = (
    p: AllowedPermitTypesProps,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
): number | null => {
    if (!login) {
        return thenElse(p.edit.allowedProductTypes, t => t.length, null);
    } else {
        return thenElse(
            p.edit.allowedProductTypes,
            ap => ap.length,
            login.allowedProductTypes.length,
        );
    }
};

const allowedRecodeConditionsCount = (
    p: AllowedPermitTypesProps,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
): number => {
    if (!login) {
        return p.edit.allowedRecodeConditions
            ? p.edit.allowedRecodeConditions.length
            : 0;
    } else {
        return p.edit.allowedRecodeConditions
            ? p.edit.allowedRecodeConditions.length
            : login.allowedRecodeConditions.length;
    }
};

const allowedPermitTypesContent = (
    p: AllowedPermitTypesProps,
    login: Maybe<CommonOperatorLoginsState.OperatorLogin>,
): string => {
    const count = allowedPermitTypesCount(p, login);
    if (count === 0) {
        return texts(p).none();
    } else if (
        count ===
        p.permitTypes.data.filter(permit => {
            return (
                permit.operatorState == 'ACTIVE' ||
                permit.hasNonArchivedContracts
            );
        }).length
    ) {
        return texts(p).all();
    } else {
        return texts(p).some();
    }
};

function allowedTypesText(
    allowedCount: number,
    totalCount: number,
): JSX.Element {
    if (allowedCount === 0) {
        return <Localized de="keine" fr="aucune" it="nessuna" en="none" />;
    } else if (allowedCount === totalCount) {
        return <Localized de="alle" fr="toutes" it="tutti" en="all" />;
    } else if (allowedCount === 1) {
        return <Localized de="eine" fr="un" it="uno" en="one" />;
    } else {
        return (
            <Localized de="mehrere" fr="plusieurs" it="diversi" en="multiple" />
        );
    }
}

function Permissions({
    products,
    recodeConditions,
}: {
    products: ProductType[];
    recodeConditions: RecodeCondition[];
}) {
    const { storeState, update } = useStore(selectState);

    const disabled = !(
        storeState.currentLogin.data &&
        (storeState.currentLogin.data.isAdmin ||
            storeState.currentLogin.data.permissions.userPermissionEdit)
    );
    const selectedId = storeState.layout.selectedOperatorLogin;
    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );
    const totalProductsCount = products.length;

    if (storeState.operatorData.data && login) {
        const hasMoreThanOneModule =
            storeState.operatorData.data.hasParkingpayModule &&
            storeState.operatorData.data.hasTaxomexModule;
        const permParkingpayPersisted =
            login.permissions.parkingpayModule || false;
        const parkingpayDisabled =
            disabled ||
            !getOrElse(storeState.edit.permParkingpay, permParkingpayPersisted);
        const parkingpayHidden = !getOrElse(
            storeState.edit.permParkingpay,
            permParkingpayPersisted,
        );
        const permTaxomexPersisted = login.permissions.taxomexModule || false;
        const taxomexDisabled =
            disabled ||
            !getOrElse(storeState.edit.permTaxomex, permTaxomexPersisted);

        const operatorAppActive =
            storeState.edit.permOperatorAppActive ??
            login.permissions.operatorAppActive;

        const enforcementAppActive =
            storeState.edit.permEnforcementAppActive ??
            login.permissions.enforcementAppActive;

        const maxAnonymizeCheckinPublicPermitAfterDays =
            storeState.currentLogin.data
                ?.maxSoftAnonymizeCheckinPublicPermitAfterDays ?? 365;

        const anonymizeAfterDays = Validation.LoginForm.getFieldValues({
            login,
            edit: storeState.edit,
            maxAnonymizeCheckinPublicPermitAfterDays,
        }).softAnonymizeCheckinPublicPermitAfterDays;

        const formErrors = Validation.LoginForm.validateForm(
            Object.assign({}, storeState, {
                login,
                maxAnonymizeCheckinPublicPermitAfterDays,
            }),
        ).errorTexts;

        const availableZoneIds = zones(
            storeState.zonesMasterData,
            storeState.parkings,
            true,
        ).map(zone => zone.id);

        const allowedZonesIds = prepareAllowedZones(storeState.edit, login);

        const filteredZonesLength = filterAllowedZonesWithAvailableZones(
            allowedZonesIds,
            availableZoneIds,
        ).length;

        const hasTicketFunctionality =
            storeState.operatorData.data?.hasTicketFunctionality;
        const parkingLabelString = useLocalized({
            de: `Parkvorgänge${hasTicketFunctionality ? ' / Ticket+' : ''}`,
            fr: `Procédures de stationnement${
                hasTicketFunctionality ? ' / Ticket+' : ''
            }`,
            it: `Procedure di parcheggio${
                hasTicketFunctionality ? ' / Ticket+' : ''
            }`,
            en: `Parkings${hasTicketFunctionality ? ' / Ticket+' : ''}`,
        });
        const refundLabelString = useLocalized({
            de: `Parkvorgänge${
                hasTicketFunctionality ? ' / Ticket+' : ''
            } - Zurückzahlen`,
            fr: `Procédures${
                hasTicketFunctionality ? ' / Ticket+' : ''
            } - Rembourser`,
            it: `Rimborsare procedure${
                hasTicketFunctionality ? ' / Ticket+' : ''
            }`,
            en: `Parkings${
                hasTicketFunctionality ? ' / Ticket+' : ''
            } - Refund`,
        });

        return (
            <FullSlideInRightColumn>
                <SingleSelection
                    focused={false}
                    label={
                        <Localized
                            de="Zonen / Parkings"
                            fr="Zones / Parkings"
                            it="Zone / Parcheggi"
                            en="Zones / Parkings"
                        />
                    }
                    selection={allowedTypesText(
                        filteredZonesLength ?? 0,
                        availableZoneIds.length,
                    )}
                    rightLabelText={`${filteredZonesLength}/${availableZoneIds.length}`}
                    onClick={() =>
                        update(store =>
                            OperatorLoginsState.Layout.stateWrite(store, {
                                zoneSelectionOpen: true,
                            }),
                        )
                    }
                />
                <Title>{'Parkingportal'}</Title>
                <PermissionSwitch
                    label={texts(storeState).permActive()}
                    editVal={storeState.edit.permOperatorAppActive}
                    persistedVal={thenElse(
                        login,
                        l => l.permissions.operatorAppActive,
                        false,
                    )}
                    write={(v: boolean) =>
                        update(store =>
                            OperatorLoginsState.Edit.stateWrite(store, {
                                permOperatorAppActive: v,
                            }),
                        )
                    }
                    disabled={disabled}
                />
                {operatorAppActive && (
                    <>
                        <Conditional
                            c={
                                (storeState.currentLogin.data || false) &&
                                (storeState.currentLogin.data.isAdmin || false)
                            }
                        >
                            <PermissionSwitch
                                label={texts(storeState).userPermissionEdit()}
                                editVal={storeState.edit.permPermissionsEdit}
                                persistedVal={thenElse(
                                    login,
                                    l => l.permissions.userPermissionEdit,
                                    false,
                                )}
                                write={(v: boolean) =>
                                    update(store =>
                                        OperatorLoginsState.Edit.stateWrite(
                                            store,
                                            {
                                                permPermissionsEdit: v,
                                            },
                                        ),
                                    )
                                }
                                disabled={disabled}
                            />
                        </Conditional>
                        <Conditional
                            c={
                                ((storeState.operatorData.data
                                    .hasOnstreetTransactions ||
                                    storeState.operatorData.data
                                        .existingOffstreetTransactionsType !=
                                        null) &&
                                    storeState.operatorData.data
                                        .hasParkingpayModule) ||
                                storeState.operatorData.data.hasTaxomexModule
                            }
                        >
                            <PermissionSwitch
                                label={parkingLabelString}
                                editVal={storeState.edit.permTransactions}
                                persistedVal={thenElse(
                                    login,
                                    l => l.permissions.transactions,
                                    false,
                                )}
                                write={(v: boolean) =>
                                    update(store =>
                                        OperatorLoginsState.Edit.stateWrite(
                                            store,
                                            {
                                                permTransactions: v,
                                                permRefundParkTransactions: v,
                                            },
                                        ),
                                    )
                                }
                                disabled={disabled}
                            />
                        </Conditional>
                        <TextField
                            inputType="number"
                            value={anonymizeAfterDays}
                            label={texts(
                                storeState,
                            ).softAnonymizeCheckinPublicPermitAfterDaysLabel()}
                            onChange={(
                                softAnonymizeCheckinPublicPermitAfterDays: string,
                            ) => {
                                update(store =>
                                    OperatorLoginsState.Edit.stateWrite(store, {
                                        softAnonymizeCheckinPublicPermitAfterDays,
                                    }),
                                );
                            }}
                            disabled={disabled}
                            min={0}
                            max={maxAnonymizeCheckinPublicPermitAfterDays}
                            errorText={FormValidation.errorText(
                                true,
                                formErrors,
                                t =>
                                    t.softAnonymizeCheckinPublicPermitAfterDays
                                        ? t.softAnonymizeCheckinPublicPermitAfterDays()
                                        : '',
                            )}
                            step={1}
                        />
                        <Conditional
                            c={storeState.operatorData.data.hasParkingpayModule}
                        >
                            <div>
                                {hasMoreThanOneModule && (
                                    <div>
                                        <SubTitle>
                                            <Localized
                                                de="Digitale Kanäle"
                                                fr="Canaux numériques"
                                                it="Canali digitali"
                                                en="Digital channels"
                                            />
                                        </SubTitle>
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permParkingpayModule()}
                                            editVal={
                                                storeState.edit.permParkingpay
                                            }
                                            persistedVal={
                                                permParkingpayPersisted
                                            }
                                            write={(v: boolean) => {
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permParkingpay: v,
                                                            permRefundParkTransactions:
                                                                !!storeState
                                                                    .edit
                                                                    .permRefundParkTransactions &&
                                                                v,
                                                            permPermitsRead:
                                                                !!storeState
                                                                    .edit
                                                                    .permPermitsRead &&
                                                                v,
                                                            permPermitsWrite:
                                                                !!storeState
                                                                    .edit
                                                                    .permPermitsWrite &&
                                                                v,
                                                            permRefundPermits:
                                                                !!storeState
                                                                    .edit
                                                                    .permRefundPermits &&
                                                                v,
                                                            permActivityStatement:
                                                                !!storeState
                                                                    .edit
                                                                    .permActivityStatement &&
                                                                v,
                                                            permWhitelistCreate:
                                                                !!storeState
                                                                    .edit
                                                                    .permWhitelistCreate &&
                                                                v,
                                                            permWhitelistDelete:
                                                                !!storeState
                                                                    .edit
                                                                    .permWhitelistDelete &&
                                                                v,
                                                            permTwintQrCodePairing:
                                                                !!storeState
                                                                    .edit
                                                                    .permTwintQrCodePairing &&
                                                                v,
                                                            permEnforcementLog:
                                                                !!storeState
                                                                    .edit
                                                                    .permEnforcementLog &&
                                                                v,
                                                            permQuotasWrite:
                                                                !!storeState
                                                                    .edit
                                                                    .permQuotasWrite &&
                                                                v,
                                                        },
                                                    ),
                                                );
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                                {!parkingpayHidden && (
                                    <div>
                                        <Conditional
                                            c={
                                                storeState.operatorData.data
                                                    .hasOnstreetTransactions ||
                                                storeState.operatorData.data
                                                    .existingOffstreetTransactionsType !=
                                                    null ||
                                                storeState.operatorData.data
                                                    .hasTaxomexModule
                                            }
                                        >
                                            <PermissionSwitch
                                                label={refundLabelString}
                                                editVal={
                                                    storeState.edit
                                                        .permRefundParkTransactions
                                                }
                                                persistedVal={thenElse(
                                                    login,
                                                    l =>
                                                        l.permissions
                                                            .refundParkTransactions,
                                                    false,
                                                )}
                                                write={(v: boolean) =>
                                                    update(store =>
                                                        OperatorLoginsState.Edit.stateWrite(
                                                            store,
                                                            {
                                                                permRefundParkTransactions:
                                                                    v,
                                                            },
                                                        ),
                                                    )
                                                }
                                                disabled={
                                                    disabled ||
                                                    !getOrElse(
                                                        storeState.edit
                                                            .permTransactions,
                                                        login.permissions
                                                            .transactions,
                                                    )
                                                }
                                            />
                                        </Conditional>

                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permActivityStatement()}
                                            editVal={
                                                storeState.edit
                                                    .permActivityStatement
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l =>
                                                    l.permissions
                                                        .activityStatement,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permActivityStatement:
                                                                v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={parkingpayDisabled}
                                        />
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permEnforcementLog()}
                                            editVal={
                                                storeState.edit
                                                    .permEnforcementLog
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l =>
                                                    l.permissions
                                                        .enforcementLog,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permEnforcementLog:
                                                                v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={parkingpayDisabled}
                                        />
                                        <Conditional
                                            c={
                                                storeState.operatorData.data
                                                    .licensePlatePermitSettings
                                                    .active
                                            }
                                        >
                                            <div>
                                                <Conditional
                                                    c={
                                                        storeState.operatorData
                                                            .data
                                                            .licensePlatePermitSettings
                                                            .whitelistActive
                                                    }
                                                >
                                                    <div>
                                                        <PermissionSwitch
                                                            label={texts(
                                                                storeState,
                                                            ).permWhitelistCreate()}
                                                            editVal={
                                                                storeState.edit
                                                                    .permWhitelistCreate
                                                            }
                                                            persistedVal={thenElse(
                                                                login,
                                                                l =>
                                                                    l
                                                                        .permissions
                                                                        .whitelistCreate,
                                                                false,
                                                            )}
                                                            write={(
                                                                v: boolean,
                                                            ) =>
                                                                update(store =>
                                                                    OperatorLoginsState.Edit.stateWrite(
                                                                        store,
                                                                        {
                                                                            permWhitelistCreate:
                                                                                v,
                                                                        },
                                                                    ),
                                                                )
                                                            }
                                                            disabled={
                                                                parkingpayDisabled
                                                            }
                                                        />
                                                        <PermissionSwitch
                                                            label={texts(
                                                                storeState,
                                                            ).permWhitelistDelete()}
                                                            editVal={
                                                                storeState.edit
                                                                    .permWhitelistDelete
                                                            }
                                                            persistedVal={thenElse(
                                                                login,
                                                                l =>
                                                                    l
                                                                        .permissions
                                                                        .whitelistDelete,
                                                                false,
                                                            )}
                                                            write={(
                                                                v: boolean,
                                                            ) =>
                                                                update(store =>
                                                                    OperatorLoginsState.Edit.stateWrite(
                                                                        store,
                                                                        {
                                                                            permWhitelistDelete:
                                                                                v,
                                                                        },
                                                                    ),
                                                                )
                                                            }
                                                            disabled={
                                                                parkingpayDisabled
                                                            }
                                                        />
                                                    </div>
                                                </Conditional>

                                                <PermissionSwitch
                                                    label={texts(
                                                        storeState,
                                                    ).permPermitsRead()}
                                                    editVal={
                                                        storeState.edit
                                                            .permPermitsRead
                                                    }
                                                    persistedVal={thenElse(
                                                        login,
                                                        l =>
                                                            l.permissions
                                                                .permitsRead,
                                                        false,
                                                    )}
                                                    write={(v: boolean) =>
                                                        update(store =>
                                                            OperatorLoginsState.Edit.stateWrite(
                                                                store,
                                                                {
                                                                    permPermitsRead:
                                                                        v,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    disabled={
                                                        parkingpayDisabled
                                                    }
                                                />
                                                <PermissionSwitch
                                                    label={texts(
                                                        storeState,
                                                    ).permPermitsWrite()}
                                                    editVal={
                                                        storeState.edit
                                                            .permPermitsWrite
                                                    }
                                                    persistedVal={thenElse(
                                                        login,
                                                        l =>
                                                            l.permissions
                                                                .permitsWrite,
                                                        false,
                                                    )}
                                                    write={(v: boolean) =>
                                                        update(store =>
                                                            OperatorLoginsState.Edit.stateWrite(
                                                                store,
                                                                {
                                                                    permPermitsWrite:
                                                                        v,
                                                                    permRefundPermits:
                                                                        v,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    disabled={
                                                        parkingpayDisabled
                                                    }
                                                />
                                                <PermissionSwitch
                                                    label={texts(
                                                        storeState,
                                                    ).permRefundPermits()}
                                                    editVal={
                                                        storeState.edit
                                                            .permRefundPermits
                                                    }
                                                    persistedVal={thenElse(
                                                        login,
                                                        l =>
                                                            l.permissions
                                                                .refundPermits,
                                                        false,
                                                    )}
                                                    write={(v: boolean) =>
                                                        update(store =>
                                                            OperatorLoginsState.Edit.stateWrite(
                                                                store,
                                                                {
                                                                    permRefundPermits:
                                                                        v,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    disabled={
                                                        parkingpayDisabled
                                                    }
                                                />
                                                <PermissionSwitch
                                                    label={
                                                        <Localized
                                                            de="Kontingente - Ändern"
                                                            fr="Contingents - Modifier"
                                                            it="Contingenti - Modificare"
                                                            en="Quotas – Edit"
                                                        />
                                                    }
                                                    editVal={
                                                        storeState.edit
                                                            .permQuotasWrite
                                                    }
                                                    persistedVal={thenElse(
                                                        login,
                                                        l =>
                                                            l.permissions
                                                                .quotasWrite,
                                                        false,
                                                    )}
                                                    write={(v: boolean) =>
                                                        update(store =>
                                                            OperatorLoginsState.Edit.stateWrite(
                                                                store,
                                                                {
                                                                    permQuotasWrite:
                                                                        v,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                    disabled={
                                                        parkingpayDisabled
                                                    }
                                                />
                                                {storeState.operatorData.data
                                                    .hasParkingaboUserInitialBalanceActive && (
                                                    <PermissionSwitch
                                                        label={
                                                            <Localized
                                                                de="Saldoguthaben - Erfassen"
                                                                fr="Solde - Saisir"
                                                                it="Saldo - Registra"
                                                                en="Balance – Create"
                                                            />
                                                        }
                                                        editVal={
                                                            storeState.edit
                                                                .permParkingaboInitialBalanceCreate
                                                        }
                                                        persistedVal={thenElse(
                                                            login,
                                                            l =>
                                                                l.permissions
                                                                    .parkingaboInitialBalanceCreate,
                                                            false,
                                                        )}
                                                        write={(v: boolean) =>
                                                            update(store =>
                                                                OperatorLoginsState.Edit.stateWrite(
                                                                    store,
                                                                    {
                                                                        permParkingaboInitialBalanceCreate:
                                                                            v,
                                                                    },
                                                                ),
                                                            )
                                                        }
                                                        disabled={false}
                                                    />
                                                )}
                                                <SingleSelection
                                                    focused={false}
                                                    label={texts(
                                                        storeState,
                                                    ).permitTypes()}
                                                    selection={allowedPermitTypesContent(
                                                        storeState,
                                                        login,
                                                    )}
                                                    rightLabelText={`${allowedPermitTypesCount(
                                                        storeState,
                                                        login,
                                                    )}/${
                                                        storeState.permitTypes.data.filter(
                                                            permit => {
                                                                return (
                                                                    permit.operatorState ==
                                                                        'ACTIVE' ||
                                                                    permit.hasNonArchivedContracts
                                                                );
                                                            },
                                                        ).length
                                                    }`}
                                                    onClick={
                                                        parkingpayDisabled
                                                            ? undefined
                                                            : () =>
                                                                  update(
                                                                      store =>
                                                                          OperatorLoginsState.Layout.stateWrite(
                                                                              store,
                                                                              {
                                                                                  permitTypeSelectionOpen:
                                                                                      true,
                                                                              },
                                                                          ),
                                                                  )
                                                    }
                                                />
                                            </div>
                                        </Conditional>
                                        <Conditional c={totalProductsCount > 0}>
                                            <div>
                                                <SingleSelection
                                                    focused={false}
                                                    label={
                                                        <Localized
                                                            de="Produkttypen"
                                                            fr="Types de produits"
                                                            it="Tipi di prodotto"
                                                            en="Product types"
                                                        />
                                                    }
                                                    selection={allowedTypesText(
                                                        allowedProductTypesCount(
                                                            storeState,
                                                            login,
                                                        ) ?? 0,
                                                        totalProductsCount,
                                                    )}
                                                    rightLabelText={`${allowedProductTypesCount(
                                                        storeState,
                                                        login,
                                                    )}/${totalProductsCount}`}
                                                    onClick={() =>
                                                        update(store =>
                                                            OperatorLoginsState.Layout.stateWrite(
                                                                store,
                                                                {
                                                                    productTypeSelectionOpen:
                                                                        true,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Conditional>
                                    </div>
                                )}
                            </div>
                        </Conditional>
                        {storeState.operatorData.data.hasCloudConnector && (
                            <div>
                                <SubTitle>
                                    <Localized
                                        de="Cloud Connector"
                                        fr="Cloud Connector"
                                        it="Cloud Connector"
                                        en="Cloud Connector"
                                    />
                                </SubTitle>
                                <PermissionSwitch
                                    label={
                                        <Localized
                                            de="Geräteansicht"
                                            fr="Vue des appareils"
                                            it="Visualizzazione di apparecchi"
                                            en="Devices view"
                                        />
                                    }
                                    editVal={
                                        storeState.edit
                                            .permCloudConnectorOffstreetDevicesView
                                    }
                                    persistedVal={thenElse(
                                        login,
                                        l =>
                                            l.permissions
                                                .cloudConnectorOffstreetDevicesView,
                                        false,
                                    )}
                                    write={(v: boolean) =>
                                        update(store =>
                                            OperatorLoginsState.Edit.stateWrite(
                                                store,
                                                {
                                                    permCloudConnectorOffstreetDevicesView:
                                                        v,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={false}
                                />
                                <PermissionSwitch
                                    label={
                                        <Localized
                                            de="Ticket - Neu Codieren"
                                            fr="Ticket - Recode"
                                            it="Ticket - Recode"
                                            en="Ticket - Recode"
                                        />
                                    }
                                    editVal={
                                        storeState.edit
                                            .permCloudConnectorRecodeTicket
                                    }
                                    persistedVal={thenElse(
                                        login,
                                        l =>
                                            l.permissions
                                                .cloudConnectorRecodeTicket,
                                        false,
                                    )}
                                    write={(v: boolean) =>
                                        update(store =>
                                            OperatorLoginsState.Edit.stateWrite(
                                                store,
                                                {
                                                    permCloudConnectorRecodeTicket:
                                                        v,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={false}
                                />
                                {(storeState.edit
                                    .permCloudConnectorRecodeTicket ??
                                    login.permissions
                                        .cloudConnectorRecodeTicket) && (
                                    <SingleSelection
                                        focused={false}
                                        label={
                                            <Localized
                                                de="Umkodierungsbedingungen"
                                                fr="Conditions de recodage"
                                                it="Condizioni di ricodifica"
                                                en="Recoding conditions"
                                            />
                                        }
                                        selection={allowedTypesText(
                                            allowedRecodeConditionsCount(
                                                storeState,
                                                login,
                                            ) ?? 0,
                                            recodeConditions.length,
                                        )}
                                        rightLabelText={`${allowedRecodeConditionsCount(
                                            storeState,
                                            login,
                                        )}/${recodeConditions.length}`}
                                        onClick={() =>
                                            update(store =>
                                                OperatorLoginsState.Layout.stateWrite(
                                                    store,
                                                    {
                                                        recodeConditionSelectionOpen:
                                                            true,
                                                    },
                                                ),
                                            )
                                        }
                                        errorText={getAllowedRecodeConditionsEmptyError(
                                            storeState.editCreateResponse,
                                        )}
                                    />
                                )}

                                <PermissionSwitch
                                    label={
                                        <Localized
                                            de="Benachrichtigungen"
                                            fr="Notifications"
                                            it="Notifiche"
                                            en="Alerts"
                                        />
                                    }
                                    editVal={
                                        storeState.edit.permCloudConnectorAlerts
                                    }
                                    persistedVal={thenElse(
                                        login,
                                        l => l.permissions.cloudConnectorAlerts,
                                        false,
                                    )}
                                    write={(v: boolean) =>
                                        update(store =>
                                            OperatorLoginsState.Edit.stateWrite(
                                                store,
                                                {
                                                    permCloudConnectorAlerts: v,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={false}
                                />
                                <PermissionSwitch
                                    label={
                                        <Localized
                                            de="Reporting Zugriff"
                                            fr="Accès au reporting"
                                            it="Accesso ai rapporti"
                                            en="Reporting access"
                                        />
                                    }
                                    editVal={
                                        storeState.edit
                                            .permCloudConnectorReportingAccess
                                    }
                                    persistedVal={thenElse(
                                        login,
                                        l =>
                                            l.permissions
                                                .cloudConnectorReportingAccess,
                                        false,
                                    )}
                                    write={(v: boolean) =>
                                        update(store =>
                                            OperatorLoginsState.Edit.stateWrite(
                                                store,
                                                {
                                                    permCloudConnectorReportingAccess:
                                                        v,
                                                },
                                            ),
                                        )
                                    }
                                    disabled={false}
                                />
                            </div>
                        )}

                        <Conditional
                            c={storeState.operatorData.data.hasTaxomexModule}
                        >
                            <div>
                                {hasMoreThanOneModule && (
                                    <div>
                                        <SubTitle>{`TOM eco`}</SubTitle>
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permTaxomexModule()}
                                            editVal={
                                                storeState.edit.permTaxomex
                                            }
                                            persistedVal={permTaxomexPersisted}
                                            write={(v: boolean) => {
                                                // module unchecked implies all subpermissions unchecked
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permTaxomex: v,
                                                            permSyncApp:
                                                                !!storeState
                                                                    .edit
                                                                    .permSyncApp &&
                                                                v,
                                                            permUsbStickEdit:
                                                                !!storeState
                                                                    .edit
                                                                    .permUsbStickEdit &&
                                                                v,
                                                            permParkingMeterEdit:
                                                                !!storeState
                                                                    .edit
                                                                    .permParkingMeterEdit &&
                                                                v,
                                                            permParkingMeterAlerts:
                                                                !!storeState
                                                                    .edit
                                                                    .permParkingMeterAlerts &&
                                                                v,
                                                        },
                                                    ),
                                                );
                                            }}
                                            disabled={disabled}
                                        />
                                    </div>
                                )}
                                {!taxomexDisabled && (
                                    <div>
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permSyncApp()}
                                            editVal={
                                                storeState.edit.permSyncApp
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l => l.permissions.syncApp,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permSyncApp: v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={taxomexDisabled}
                                        />

                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permUsbStickWrite()}
                                            editVal={
                                                storeState.edit.permUsbStickEdit
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l => l.permissions.usbStickEdit,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permUsbStickEdit: v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={taxomexDisabled}
                                        />
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permParkingMeterWrite()}
                                            editVal={
                                                storeState.edit
                                                    .permParkingMeterEdit
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l =>
                                                    l.permissions
                                                        .parkingMeterEdit,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permParkingMeterEdit:
                                                                v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={taxomexDisabled}
                                        />
                                        <PermissionSwitch
                                            label={texts(
                                                storeState,
                                            ).permParkingMeterAlerts()}
                                            editVal={
                                                storeState.edit
                                                    .permParkingMeterAlerts
                                            }
                                            persistedVal={thenElse(
                                                login,
                                                l =>
                                                    l.permissions
                                                        .parkingMeterAlerts,
                                                false,
                                            )}
                                            write={(v: boolean) =>
                                                update(store =>
                                                    OperatorLoginsState.Edit.stateWrite(
                                                        store,
                                                        {
                                                            permParkingMeterAlerts:
                                                                v,
                                                        },
                                                    ),
                                                )
                                            }
                                            disabled={taxomexDisabled}
                                        />
                                    </div>
                                )}
                            </div>
                        </Conditional>
                    </>
                )}

                <div>
                    <Title>{`Parkingcheck`}</Title>
                    <PermissionSwitch
                        label={texts(storeState).permActive()}
                        editVal={storeState.edit.permEnforcementAppActive}
                        persistedVal={thenElse(
                            login,
                            l => l.permissions.enforcementAppActive,
                            false,
                        )}
                        write={(v: boolean) =>
                            update(store =>
                                OperatorLoginsState.Edit.stateWrite(store, {
                                    permEnforcementAppActive: v,
                                }),
                            )
                        }
                        disabled={disabled}
                    />
                    {enforcementAppActive && (
                        <Conditional
                            c={storeState.operatorData.data.hasParkingpayModule}
                        >
                            <PermissionSwitch
                                label={texts(
                                    storeState,
                                ).permTwintQrCodePairing()}
                                editVal={storeState.edit.permTwintQrCodePairing}
                                persistedVal={thenElse(
                                    login,
                                    l => l.permissions.twintQrCodePairing,
                                    false,
                                )}
                                write={(v: boolean) =>
                                    update(store =>
                                        OperatorLoginsState.Edit.stateWrite(
                                            store,
                                            {
                                                permTwintQrCodePairing: v,
                                            },
                                        ),
                                    )
                                }
                                disabled={parkingpayDisabled}
                            />
                        </Conditional>
                    )}
                </div>
            </FullSlideInRightColumn>
        );
    } else {
        return null;
    }
}

function Body({
    currentLogin,
    products,
    recodeConditions,
}: {
    currentLogin: CurrentOperatorLogin;
    products: ProductType[];
    recodeConditions: RecodeCondition[];
}) {
    const { storeState, update } = useStore(selectState);
    const selectedId = storeState.layout.selectedOperatorLogin;
    const operatorData = storeState.operatorData.data;

    const login = CommonOperatorLoginsState.getLoginById(
        storeState.operatorLoginsList.data,
        selectedId ? selectedId : -1,
    );

    const canChangeMetaInfo = !login || login.editableMetaInfo;
    const canChangePassword = !login || login.editablePassword;

    if (!operatorData) {
        return null;
    } else if (!storeState.layout.createEnabled && !login) {
        return null;
    } else {
        return (
            <SlideInBody disabled={storeState.layout.editPasswordOpen}>
                <FullSlideInLeftColumn>
                    <LoginMeta
                        {...storeState}
                        update={update}
                        login={login}
                        maxAnonymizeCheckinPublicPermitAfterDays={
                            currentLogin.softAnonymizeCheckinPublicPermitAfterDays
                        }
                        isPasswordDefined={
                            !storeState.layout.createEnabled ||
                            !!storeState.passwordEdit.password1
                        }
                        canChangeMetaInfo={canChangeMetaInfo}
                        canChangePassword={canChangePassword}
                    />
                </FullSlideInLeftColumn>
                <Permissions
                    products={products}
                    recodeConditions={recodeConditions}
                />
            </SlideInBody>
        );
    }
}

export function OperatorLoginsDetailSlideIn({
    currentLogin,
    products,
    recodeConditions,
}: {
    currentLogin: CurrentOperatorLogin;
    products: ProductType[];
    recodeConditions: RecodeCondition[];
}) {
    const { storeState } = useStore(store => ({
        layout: OperatorLoginsState.Layout.get(store),
        currentLogin: CurrentOperatorLoginState.get(store),
        operatorLoginsList: CommonOperatorLoginsState.List.get(store),
    }));
    const selectedLogin = storeState.layout.selectedOperatorLogin
        ? CommonOperatorLoginsState.getLoginById(
              storeState.operatorLoginsList.data,
              storeState.layout.selectedOperatorLogin,
          )
        : null;

    return (
        <FullSlideIn
            open={
                (selectedLogin &&
                    !selectedLogin.permissions.userPermissionEdit) ||
                storeState.layout.createEnabled
            }
            disabled={
                storeState.layout.permitTypeSelectionOpen ||
                storeState.layout.zoneSelectionOpen
            }
        >
            <Body
                currentLogin={currentLogin}
                products={products}
                recodeConditions={recodeConditions}
            />
            <Header currentLogin={currentLogin} />
        </FullSlideIn>
    );
}

function filterAllowedZonesWithAvailableZones(
    allowedZonesId: number[],
    availableZonesId: number[],
) {
    return allowedZonesId.filter(zoneId => availableZonesId.includes(zoneId));
}

function getAllowedRecodeConditionsEmptyError(
    editCreateResponse: OperatorLoginsState.EditCreateResponse.State,
): JSX.Element | null {
    if (
        editCreateResponse.statusCode.badRequest &&
        isDefined(editCreateResponse.errorData) &&
        (editCreateResponse.errorData.message as ApiError) ===
            'ALLOWED_RECODE_CONDITIONS_EMPTY'
    ) {
        return (
            <Localized
                de="Es muss mindestens eine Umkodierungsbedingung ausgewählt werden, oder die 'Ticket - neu codieren' Berechtigung abgewählt werden"
                fr="Au moins une condition d'encodage doit être sélectionnée, ou l'autorisation 'Ticket - recode' doit être désélectionnée"
                it="È necessario selezionare almeno una condizione di ricodifica oppure deselezionare l'autorizzazione 'Ticket - recode'."
                en="At least one recoding condition must be selected, or the 'Ticket - recode' permission must be deselected"
            />
        );
    } else {
        return null;
    }
}
