import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Typography,
} from '@mui/material';

import {
    ServerRequestState,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { ParkingaboUser, Tenant } from './OperatorParkingaboUsersForm.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    ParkingaboVehicle,
    VehicleLicensePlateType,
} from 'dg-web-shared/common/models/Vehicle.tsx';
import { VehicleTypeIcon } from 'dg-web-shared/common/components/material-ui/VehicleTypeIcon.tsx';
import {
    generatePath,
    Outlet,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {
    TenantAllowedBarrierGateVehicleIdentification,
    TenantAllowedEnforcedVehicleIdentification,
    TenantPaymentMode,
} from 'dg-web-shared/model/TenantEnums.ts';
import {
    LastUpdateTimestampAndRefresh,
    LastUpdateTimestampAndRefreshAsyncLoadedSection,
} from '../ui/LastUpdateTimestampAndRefresh.tsx';
import { ResultLimitAlert } from '../shared-mui-components/ListMessages.tsx';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import {
    DetailHeader,
    DetailTabs,
    ParkingaboUserInfoTab,
    useParkingaboUserDetailOutletContext,
    UserDeailsSearchBar,
} from './OperatorParkingaboUsersDetail.tsx';
import { useParkingaboUserFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { formatBadgeLabelByType } from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';
import { CustomerTenantState } from 'dg-web-shared/model/CustomerTenantState.ts';

export interface ParkingaboVehicleSearchResult {
    results: ParkingaboVehicle[];
    eof: boolean;
}

export function OperatorParkingaboUsersVehicles() {
    const { parkingaboUser, refetchParkingaboUser } =
        useParkingaboUserDetailOutletContext();
    const [vehiclesState, refetchList] = useServerFetch<
        ParkingaboVehicleSearchResult,
        { customerNr: string },
        null
    >(
        context => ({
            url: `/ui-api/operator-account/parkingabo/customer/${context.customerNr}/vehicles`,
        }),
        { customerNr: parkingaboUser.customerNr },
    );

    const { activeFilters, searchText } =
        useParkingaboUserFilterConfiguration();
    const queryFilters = makeSearchQueryWithFilters(searchText, activeFilters);
    const navigate = useNavigate();

    return (
        <OperatorRoutedModalContent
            header={<DetailHeader />}
            bar={
                <DetailTabs
                    customerNr={parkingaboUser.customerNr}
                    parkingaboUserInfoTab={ParkingaboUserInfoTab.VEHICLES}
                    tenantHasDigitalPurchase={
                        parkingaboUser.tenant.paymentMode ===
                        TenantPaymentMode.DIGITAL_PURCHASE
                    }
                />
            }
            style={{ padding: '0px 0px' }}
            body={
                <>
                    <UserDeailsSearchBar
                        button={
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    navigate(
                                        `${generatePath(
                                            '/parkingabo/users/:customerNr/vehicles/add',
                                            {
                                                customerNr:
                                                    parkingaboUser.customerNr,
                                            },
                                        )}${queryFilters}`,
                                    )
                                }
                                disabled={
                                    parkingaboUser.state !==
                                        CustomerTenantState.ACTIVE ||
                                    parkingaboUser.needsProduct
                                }
                            >
                                <Localized
                                    de="Hinzufügen"
                                    fr="Ajouter"
                                    it="Aggiungi"
                                    en="Add"
                                />
                            </Button>
                        }
                        refreshButton={
                            <LastUpdateTimestampAndRefreshAsyncLoadedSection
                                requestState={vehiclesState}
                                onRefresh={refetchList}
                                render={(vehicles, timestamp) => (
                                    <LastUpdateTimestampAndRefresh
                                        resultsCount={vehicles.results.length}
                                        eof={vehicles.eof}
                                        onRefresh={refetchList}
                                        timestamp={timestamp}
                                    />
                                )}
                            />
                        }
                    />
                    <Box
                        sx={{
                            flex: '1 1 0',
                            display: 'flex',
                            flexDirection: 'column',
                            overflowY: 'auto',
                        }}
                    >
                        <VehicleCardSection
                            vehiclesState={vehiclesState}
                            parkingaboUser={parkingaboUser}
                            onGoToDetails={(vehicle: ParkingaboVehicle) =>
                                navigate(
                                    `${generatePath(
                                        '/parkingabo/users/:customerNr/vehicles/:vehicleId',
                                        {
                                            customerNr:
                                                parkingaboUser.customerNr,
                                            vehicleId:
                                                vehicle.customerTenantCarId,
                                        },
                                    )}${queryFilters}`,
                                )
                            }
                        />
                    </Box>

                    <UserVehiclesOutlet
                        context={{
                            parkingaboUser: parkingaboUser,
                            vehiclesState: vehiclesState,
                            refetchList: refetchList,
                            refetchParkingaboUser: refetchParkingaboUser,
                        }}
                    />
                </>
            }
        />
    );
}

export function useUserVehiclesOutlet() {
    return useOutletContext<UserVehiclesOutletContext>();
}

function UserVehiclesOutlet({
    context,
}: {
    context: UserVehiclesOutletContext;
}) {
    return <Outlet context={context} />;
}

type UserVehiclesOutletContext = {
    parkingaboUser: ParkingaboUser;
    vehiclesState: ServerRequestState<ParkingaboVehicleSearchResult, null>;
    refetchList: () => void;
    refetchParkingaboUser: () => void;
};

function getVehicleHeaderForTenant(
    vehicle: ParkingaboVehicle,
    tenantOfUser: Tenant,
) {
    const formattedLp = `${vehicle.licensePlateNr} (${vehicle.country || ' - '})`;
    switch (tenantOfUser.allowedEnforcedVehicleIdentification) {
        case TenantAllowedEnforcedVehicleIdentification.LICENSE_PLATE:
            return formattedLp;
        case null:
            switch (tenantOfUser.allowedBarrierGateVehicleIdentification) {
                case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
                case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
                    return formattedLp;
                case TenantAllowedBarrierGateVehicleIdentification.BADGE:
                    return formatBadgeLabelByType(
                        vehicle.badgeLabelNr,
                        vehicle.badgeType,
                    );
                case null:
                    return '';
            }
    }
}

function getVehicleFooterForTenant(
    vehicle: ParkingaboVehicle,
    tenantOfUser: Tenant,
) {
    const formattedLp = `${vehicle.licensePlateNr} (${vehicle.country || ' - '})`;
    switch (tenantOfUser.allowedBarrierGateVehicleIdentification) {
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR:
            return vehicle.identificationQrCodeId;
        case TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_BADGE:
            return vehicle.badgeLabelNr;
        case TenantAllowedBarrierGateVehicleIdentification.BADGE:
            if (
                tenantOfUser.allowedEnforcedVehicleIdentification ===
                TenantAllowedEnforcedVehicleIdentification.LICENSE_PLATE
            ) {
                return vehicle.badgeLabelNr;
            }

            return vehicle.licensePlateNr !== null ? formattedLp : '';
        case null:
            return '';
    }
}

function VehicleCardSection({
    vehiclesState,
    parkingaboUser,
    onGoToDetails,
}: {
    vehiclesState: ServerRequestState<ParkingaboVehicleSearchResult, null>;
    parkingaboUser: ParkingaboUser;
    onGoToDetails: (vehicle: ParkingaboVehicle) => void;
}) {
    return (
        <OperatorAsyncLoadedSection
            requestState={vehiclesState}
            render={vehicles => {
                if (vehicles.results.length === 0) {
                    return (
                        <>
                            {parkingaboUser.needsProduct ? (
                                <Alert severity="warning">
                                    <Localized
                                        de="Es ist nicht möglich, Fahrzeuge hinzuzufügen, da noch kein Produkt registriert wurde."
                                        fr="Il n'est pas possible d'ajouter des véhicules, car aucun produit n'a encore été enregistré."
                                        it="Non è possibile aggiungere veicoli, poiché non è stato ancora registrato alcun prodotto."
                                        en="It is not possible to add vehicles, as no product has yet been registered."
                                    />
                                </Alert>
                            ) : (
                                <Alert severity="info">
                                    <Localized
                                        de="Dieser Benutzer hat keine Fahrzeuge."
                                        fr="Cet utilisateur n'a pas de véhicules."
                                        it="Questo utente non ha veicoli."
                                        en="This user has no vehicles."
                                    />
                                </Alert>
                            )}
                        </>
                    );
                }

                return (
                    <>
                        <Box
                            sx={{
                                flex: '0 1 auto',
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 3,
                                padding: 3,
                            }}
                        >
                            {vehicles.results.map(vehicle => (
                                <Card
                                    key={vehicle.customerTenantCarId}
                                    sx={{
                                        minWidth: 220,
                                    }}
                                    elevation={0}
                                    onClick={() => onGoToDetails(vehicle)}
                                >
                                    <CardActionArea
                                        sx={{
                                            height: '100%',
                                        }}
                                        component="div"
                                    >
                                        <CardHeader
                                            title={getVehicleHeaderForTenant(
                                                vehicle,
                                                parkingaboUser.tenant,
                                            )}
                                            sx={{
                                                paddingBottom: 0,
                                                textAlign: 'center',
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                paddingTop: 0,
                                                textAlign: 'center',
                                                color: theme =>
                                                    theme.palette.blue.main,
                                            }}
                                        >
                                            <Typography variant="body2">
                                                {vehicle.description}
                                            </Typography>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <VehicleTypeIcon
                                                    sx={{
                                                        fontSize: 50,
                                                    }}
                                                    type={
                                                        vehicle.type ||
                                                        VehicleLicensePlateType.CAR
                                                    }
                                                />
                                            </Box>
                                            <Typography variant="body2">
                                                {getVehicleFooterForTenant(
                                                    vehicle,
                                                    parkingaboUser.tenant,
                                                )}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                        </Box>
                        <ResultLimitAlert
                            eof={vehicles.eof}
                            numberOfResults={vehicles.results.length}
                            hasExport={false}
                        />
                    </>
                );
            }}
        />
    );
}
