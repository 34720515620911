import { Localized } from '../common/components/Localized.tsx';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { RecodeErrorType } from './CloudConnectorRecodeTicketConditionsOutlet.tsx';
import { useNavigate, useParams } from 'react-router-dom';

export function CloudConnectorRecodeSuccessResponse() {
    return (
        <ResponseDialog>
            <>
                <DialogTitle
                    sx={{
                        backgroundColor: theme => theme.palette.success.main,
                        color: theme => theme.palette.white.main,
                    }}
                >
                    <Localized
                        de="Umkodierung erfolgreich"
                        fr="Recode réussi"
                        it="Ricodifica riuscita"
                        en="Recode successful"
                    />
                </DialogTitle>
                <DialogContent sx={{ marginTop: '15px' }}>
                    <Localized
                        de="Das Ticket wurde erfolgreich umkodiert."
                        fr="Le ticket a été recodé avec succès."
                        it="Il ticket è stato ricodificato con successo."
                        en="The ticket was successfully recoded."
                    />
                </DialogContent>
            </>
        </ResponseDialog>
    );
}

export function CloudConnectorRecodeErrorResponse(): JSX.Element {
    const { type } = useParams<{ type: RecodeErrorType }>();
    if (!type) {
        return (
            <ResponseDialog>
                <>
                    <RecodeResponseErrorTitle />
                    <DialogContent sx={{ marginTop: '15px' }}>
                        <Localized
                            de="Ein unerwarteter Fehler ist aufgetreten."
                            fr="Une erreur inattendue s'est produite."
                            it="Si è verificato un errore imprevisto."
                            en="An unexpected error has occurred."
                        />
                    </DialogContent>
                </>
            </ResponseDialog>
        );
    }

    switch (type) {
        case RecodeErrorType.ALREADY_PAID:
            return (
                <ResponseDialog>
                    <>
                        <RecodeResponseErrorTitle />
                        <DialogContent sx={{ marginTop: '15px' }}>
                            <Localized
                                de="Das Ticket wurde bereits bezahlt."
                                fr="Le ticket a déjà été payé."
                                it="Il ticket è già stato pagato."
                                en="The ticket has already been paid."
                            />
                        </DialogContent>
                    </>
                </ResponseDialog>
            );
        case RecodeErrorType.IDENTIFICATION_UNKNOWN:
            return (
                <ResponseDialog>
                    <>
                        <RecodeResponseErrorTitle />
                        <DialogContent sx={{ marginTop: '15px' }}>
                            <Localized
                                de="Das Ticket ist unbekannt."
                                fr="Le ticket est inconnue."
                                it="Il ticket è sconosciuto."
                                en="The ticket is unknown."
                            />
                        </DialogContent>
                    </>
                </ResponseDialog>
            );
        case RecodeErrorType.PARSING_FAILED:
            return (
                <ResponseDialog>
                    <>
                        <RecodeResponseErrorTitle />
                        <DialogContent sx={{ marginTop: '15px' }}>
                            <Localized
                                de="Der Inhalt des QR-Codes ist nicht der eines Tickets."
                                fr="Le contenu du code QR n'est pas celui d'un ticket."
                                it="Il contenuto del QR code non è quello di un ticket."
                                en="The content of the QR code is not that of a ticket."
                            />
                        </DialogContent>
                    </>
                </ResponseDialog>
            );
        case RecodeErrorType.ONE_TIME_EXIT_SCANNED:
            return (
                <ResponseDialog>
                    <>
                        <RecodeResponseErrorTitle />
                        <DialogContent sx={{ marginTop: '15px' }}>
                            <Localized
                                de="Einmalausfahrten können nicht umkodiert werden."
                                fr="Les sorties uniques ne peuvent pas être recodées."
                                it="Le uscite singole non possono essere ricodificate."
                                en="One-time exits cannot be recoded."
                            />
                        </DialogContent>
                    </>
                </ResponseDialog>
            );
        case RecodeErrorType.ZONE_NOT_AVAILABLE:
            return (
                <ResponseDialog>
                    <>
                        <RecodeResponseErrorTitle />
                        <DialogContent sx={{ marginTop: '15px' }}>
                            <Localized
                                de="Keine Umkodierungsbedingungen ist verfügbar."
                                fr="Aucune condition de recodage n'est disponible."
                                it="Nessuna condizione di ricodifica è disponibile."
                                en="No recoding conditions are available."
                            />
                        </DialogContent>
                    </>
                </ResponseDialog>
            );
    }
}

function RecodeResponseErrorTitle() {
    return (
        <DialogTitle
            sx={{
                backgroundColor: theme => theme.palette.error.main,
                color: theme => theme.palette.white.main,
            }}
        >
            <Localized
                de="Umkodierung fehlgeschlagen"
                fr="Échec du recodage"
                it="Ricodifica non riuscita"
                en="Recode failed"
            />
        </DialogTitle>
    );
}

function ResponseDialog({ children }: { children: JSX.Element }) {
    const navigate = useNavigate();
    return (
        <Dialog open={true} maxWidth="xs">
            {children}
            <DialogActions>
                <Button
                    onClick={() =>
                        navigate('/mobile/recode', { replace: true })
                    }
                >
                    <Localized de="OK" fr="OK" it="OK" en="OK" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
