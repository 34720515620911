import { css } from '@emotion/css';
import { Localized } from '../common/components/Localized';
import { Language } from 'dg-web-shared/lib/Localized';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports.ts';
import operatorTermOfUseV2Files = Legal.operatorTermOfUseV2Files;
import operatorTermOfUseFiles = Legal.operatorTermOfUseFiles;

export function TermOfUseLink({
    lang,
    hasContractVersionAVBV2,
}: {
    lang: Language;
    hasContractVersionAVBV2?: boolean;
}) {
    return (
        <div>
            <a
                className={css({
                    fontSize: 13,
                    marginRight: 32,
                    ...Typo.robotoRegular,
                    color: Colors.white,
                    ':hover': {
                        color: Colors.lightblue,
                    },
                })}
                target="_blank"
                href={
                    hasContractVersionAVBV2
                        ? operatorTermOfUseV2Files[lang]
                        : operatorTermOfUseFiles[lang]
                }
                rel="noreferrer"
            >
                <Localized
                    de="Nutzungsbedingungen"
                    fr="Conditions d’exploitation"
                    it="Condizioni d'utilizzo"
                    en="Term of use"
                />
            </a>
        </div>
    );
}
