import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { PaymentChannel } from '../common/models/PaymentChannel.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import {
    EmptyResults,
    FetchError,
    ResultLimitAlert,
} from '../shared-mui-components/ListMessages.tsx';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import {
    CloudDownload,
    Error,
    PanTool,
    Pending,
    PlayArrow,
    PlayCircle,
    StopCircle,
} from '@mui/icons-material';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { DateTime } from 'luxon';
import { HeaderWithButtons } from '../shared-mui-components/HeaderWithButtons.tsx';
import { FiltersBox } from '../shared-mui-components/filter/FiltersBox.tsx';
import { useOffstreetTransactionsFilterConfiguration } from '../shared-mui-components/filter/OperatorFilterConfiguration.tsx';
import {
    generatePath,
    Outlet,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import { makeSearchQueryWithFilters } from '../shared-mui-components/filter/OperatorFilterHelpers.tsx';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { ExistingOffstreetTransactionsType } from '../common/state/CurrentOperatorLoginState.ts';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
    BarrierGateIdentification,
    IdentificationIcon,
} from '../common/components/IdentificationItems.tsx';
import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon.tsx';
import { LicensePlateType } from 'dg-web-shared/dto/LicensePlateType.ts';
import { Colors } from 'dg-web-shared/ui/vars.ts';
import { BarrierGateIdentificationEntity } from './OperatorOffstreetTransactionTypes.ts';
import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { TranslationForTicketState } from './OperatorOffstreetTransactionTicket.tsx';

export enum TicketState {
    CREATED = 'CREATED',
    SCANNED = 'SCANNED',
    ALIAS_PENDING = 'ALIAS_PENDING',
    APPROVED = 'APPROVED',
    BOOKED = 'BOOKED',
}

enum EntryWithoutExitState {
    RUNNING = 'RUNNING',
    RUNNING_WITH_ANTIPASSBACK = 'RUNNING_WITH_ANTIPASSBACK',
    COMPLETE = 'COMPLETE',
}

type OffstreetTransactionEntry =
    | PendingTicketTransactionEntry
    | PaidTicketTransactionEntry
    | EntryWithoutExitTransactionEntry
    | ContractTransactionEntry;

interface OffstreetTransactionEntryBase {
    zone: string;
    city: string;
    validFrom: string;
    type: TransactionType;
}

enum TransactionType {
    PENDING_TICKET = 'PENDING_TICKET',
    PAID_TICKET = 'PAID_TICKET',
    ENTRY_WITHOUT_EXIT = 'ENTRY_WITHOUT_EXIT',
    CONTRACT = 'CONTRACT',
}

interface PendingTicketTransactionEntry extends OffstreetTransactionEntryBase {
    ticketId: string;
    ticketApiString: string;
    ticketState: TicketState;
    type: TransactionType.PENDING_TICKET;
}

interface PaidTicketTransactionEntry extends OffstreetTransactionEntryBase {
    ticketId: string;
    ticketApiString: string;
    ticketState: TicketState;
    validTo: string;
    duration: string;
    paymentChannel: PaymentChannel;
    amount: number;
    isRefunded: boolean;
    type: TransactionType.PAID_TICKET;
}

interface EntryWithoutExitTransactionEntry
    extends OffstreetTransactionEntryBase {
    paymentChannel: PaymentChannel;
    entryWithoutExitId: number;
    entryWithoutExitState: EntryWithoutExitState;
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    type: TransactionType.ENTRY_WITHOUT_EXIT;
}

interface ContractTransactionEntry extends OffstreetTransactionEntryBase {
    contractId: number;
    barrierGateIdentificationEntity: BarrierGateIdentificationEntity;
    validTo: string;
    duration: string;
    paymentChannel: PaymentChannel;
    amount: number;
    isRefunded: boolean;
    type: TransactionType.CONTRACT;
}

export interface OffstreetTransactionsSearchResult {
    eof: boolean;
    results: OffstreetTransactionEntry[];
}

export function OperatorOffstreetTransactionsRouteLayout() {
    const { operatorData } = useOperatorContext();
    const navigate = useNavigate();
    const language = useOperatorLanguage();

    const {
        filterConfig,
        activeFilters,
        listState,
        refetchList,
        searchText,
        filterConfigState,
    } = useOffstreetTransactionsFilterConfiguration();

    if (operatorData.existingOffstreetTransactionsType === null) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    return (
        <MainContent>
            <HeaderWithButtons
                title={
                    <OffstreetTransactionsTitle
                        offstreetTransactionsType={
                            operatorData.existingOffstreetTransactionsType
                        }
                    />
                }
                icon={PlayArrow}
                headerButtons={
                    <Button
                        variant="outlined"
                        startIcon={<CloudDownload />}
                        sx={{
                            marginLeft: 3,
                            marginRight: 3,
                        }}
                        href={`/ui-api/operator-account/offstreet-transactions/${language}/csv-export${makeSearchQueryWithFilters(
                            searchText,
                            activeFilters,
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                        disabled={listState.status == RequestStatus.PENDING}
                    >
                        <Localized
                            de="Exportieren"
                            fr="Exporter"
                            it="Esporta"
                            en="Export"
                        />
                    </Button>
                }
            />
            <FiltersBox
                searchText={searchText}
                activeFilters={activeFilters}
                filterConfig={filterConfig}
                requestState={listState}
                refetch={refetchList}
                pending={filterConfigState.status === RequestStatus.PENDING}
            />
            <OperatorAsyncLoadedSection
                requestState={listState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                renderError={() => <FetchError refetchList={refetchList} />}
                render={data => {
                    return (
                        <OffstreetTransactionsList
                            data={data}
                            onRowClick={(
                                transactionType: TransactionType,
                                id: string,
                            ) => {
                                navigate(
                                    `${generatePath(
                                        `/offstreet-transactions/${getRouteForTransactionType(transactionType)}/:id/detail`,
                                        {
                                            id: id,
                                        },
                                    )}${makeSearchQueryWithFilters(
                                        searchText,
                                        activeFilters,
                                    )}`,
                                );
                            }}
                        />
                    );
                }}
            />
            <OffstreetTransactionsOutlet
                context={{ refetchList: refetchList }}
            />
        </MainContent>
    );
}

export function useOffstreetTransactionsOutlet() {
    return useOutletContext<OffstreetTransactionsOutletContext>();
}

export function OffstreetTransactionsOutlet({
    context,
}: {
    context: OffstreetTransactionsOutletContext;
}) {
    return <Outlet context={context} />;
}

export interface OffstreetTransactionsOutletContext {
    refetchList: () => void;
}

function getRouteForTransactionType(type: TransactionType): string {
    switch (type) {
        case TransactionType.PENDING_TICKET:
        case TransactionType.PAID_TICKET:
            return 'ticket';
        case TransactionType.CONTRACT:
            return 'contract';
        case TransactionType.ENTRY_WITHOUT_EXIT:
            return 'entry-without-exit';
    }
}

function OffstreetTransactionsList({
    data,
    onRowClick,
}: {
    data: OffstreetTransactionsSearchResult;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}) {
    if (data.results.length === 0) {
        return <EmptyResults />;
    }

    return (
        <>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCellLayout firstCell>
                                <Localized
                                    de="Anwesenheit"
                                    fr="Présence"
                                    it="Presenza"
                                    en="Presence"
                                />
                            </TableCellLayout>
                            <TableCellLayout>
                                <Localized
                                    de="Identifikation"
                                    fr="Identification"
                                    it="Identificazione"
                                    en="Identification"
                                />
                            </TableCellLayout>
                            <TableCellLayout>
                                <Localized
                                    de="Parking"
                                    fr="Parking"
                                    it="Parcheggio"
                                    en="Parking"
                                />
                            </TableCellLayout>
                            <TableCellLayout>
                                <Localized
                                    de="Parkzeit"
                                    fr="Heures de stationnement"
                                    it="Orario di sosta"
                                    en="Park time"
                                />
                            </TableCellLayout>
                            <TableCellLayout>
                                <Localized
                                    de="Kanal"
                                    fr="Canal"
                                    it="Canale"
                                    en="Channel"
                                />
                            </TableCellLayout>
                            <TableCellLayout>
                                <Localized
                                    de="Dauer"
                                    fr="Durée"
                                    it="Durata"
                                    en="Duration"
                                />
                            </TableCellLayout>
                            <TableCellLayout lastCell>
                                <Localized
                                    de="Bezahlt"
                                    fr="Payé"
                                    it="Pagato"
                                    en="Paid"
                                />
                            </TableCellLayout>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.results.map(transaction => (
                            <OffstreetTransactionRow
                                key={getKeyForTransaction(transaction)}
                                transaction={transaction}
                                onRowClick={onRowClick}
                            />
                        ))}
                    </TableBody>
                </Table>
                <ResultLimitAlert
                    eof={data.eof}
                    numberOfResults={data.results.length}
                    hasExport={false}
                />
            </TableContainer>
        </>
    );
}

function getKeyForTransaction(transaction: OffstreetTransactionEntry) {
    switch (transaction.type) {
        case TransactionType.PENDING_TICKET:
        case TransactionType.PAID_TICKET:
            return transaction.ticketId;
        case TransactionType.ENTRY_WITHOUT_EXIT:
            return transaction.entryWithoutExitId;
        case TransactionType.CONTRACT:
            return transaction.contractId;
    }
}

function OffstreetTransactionRow({
    transaction,
    onRowClick,
}: {
    transaction: OffstreetTransactionEntry;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}): JSX.Element {
    switch (transaction.type) {
        case TransactionType.PENDING_TICKET:
            return (
                <PendingTicketTransactionRow
                    transaction={transaction}
                    onRowClick={onRowClick}
                />
            );
        case TransactionType.PAID_TICKET:
            return (
                <PaidTicketTransactionRow
                    transaction={transaction}
                    onRowClick={onRowClick}
                />
            );
        case TransactionType.ENTRY_WITHOUT_EXIT:
            return (
                <EntryWithoutExitTransactionRow
                    transaction={transaction}
                    onRowClick={onRowClick}
                />
            );
        case TransactionType.CONTRACT:
            return (
                <ContractTransactionRow
                    transaction={transaction}
                    onRowClick={onRowClick}
                />
            );
    }
}

function PendingTicketTransactionRow({
    transaction,
    onRowClick,
}: {
    transaction: PendingTicketTransactionEntry;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}) {
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => onRowClick(transaction.type, transaction.ticketId)}
        >
            <TableCellLayout firstCell>
                <PresenceWithTooltip
                    icon={<RunningTransactionIcon />}
                    title={<InTheParkingTranslation />}
                />
            </TableCellLayout>
            <TableCellLayout>
                <TicketIdentificationDisplay
                    ticketApiString={transaction.ticketApiString}
                />
            </TableCellLayout>
            <TableCellLayout>
                <ParkingDescription transaction={transaction} />
            </TableCellLayout>
            <TableCellLayout>
                {Formatter.dayMonthYearHourMinute(
                    DateTime.fromISO(transaction.validFrom),
                )}
            </TableCellLayout>
            <TableCellLayout>{EmDash}</TableCellLayout>
            <TableCellLayout>{EmDash}</TableCellLayout>
            <TableCellLayout lastCell>
                <PendingTicketPaidDisplay
                    ticketState={transaction.ticketState}
                />
            </TableCellLayout>
        </TableRow>
    );
}

function PaidTicketTransactionRow({
    transaction,
    onRowClick,
}: {
    transaction: PaidTicketTransactionEntry;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}) {
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() => onRowClick(transaction.type, transaction.ticketId)}
        >
            <TableCellLayout firstCell>
                <PresenceWithTooltip
                    icon={<CompletedTransactionIcon />}
                    title={<ExitedTranslation />}
                />
            </TableCellLayout>
            <TableCellLayout>
                <TicketIdentificationDisplay
                    ticketApiString={transaction.ticketApiString}
                />
            </TableCellLayout>
            <TableCellLayout>
                <ParkingDescription transaction={transaction} />
            </TableCellLayout>
            <TableCellLayout>
                <ValidFromValidToDisplay
                    validFrom={transaction.validFrom}
                    validTo={transaction.validTo}
                />
            </TableCellLayout>
            <TableCellLayout>
                <NicerPaymentChannel
                    paymentChannel={transaction.paymentChannel}
                />
            </TableCellLayout>
            <TableCellLayout>{transaction.duration}</TableCellLayout>
            <TableCellLayout lastCell>
                {`CHF ${transaction.amount.toFixed(2)} ${transaction.isRefunded ? '(R)' : ''}`}
            </TableCellLayout>
        </TableRow>
    );
}

function EntryWithoutExitTransactionRow({
    transaction,
    onRowClick,
}: {
    transaction: EntryWithoutExitTransactionEntry;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}) {
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() =>
                onRowClick(
                    transaction.type,
                    transaction.entryWithoutExitId.toString(),
                )
            }
        >
            <TableCellLayout firstCell>
                <PresenceWithTooltip
                    icon={
                        <IconForEntryWithoutExitState
                            entryWithoutExitState={
                                transaction.entryWithoutExitState
                            }
                        />
                    }
                    title={
                        <TranslationForEntryWithoutExitState
                            entryWithoutExitState={
                                transaction.entryWithoutExitState
                            }
                        />
                    }
                />
            </TableCellLayout>
            <TableCellLayout>
                <BarrierGateIdentificationEntityDisplay
                    identificationEntity={
                        transaction.barrierGateIdentificationEntity
                    }
                />
            </TableCellLayout>
            <TableCellLayout>
                <ParkingDescription transaction={transaction} />
            </TableCellLayout>
            <TableCellLayout>
                {Formatter.dayMonthYearHourMinute(
                    DateTime.fromISO(transaction.validFrom),
                )}
            </TableCellLayout>
            <TableCellLayout>
                <NicerPaymentChannel
                    paymentChannel={transaction.paymentChannel}
                />
            </TableCellLayout>
            <TableCellLayout>{EmDash}</TableCellLayout>
            <TableCellLayout lastCell>{EmDash}</TableCellLayout>
        </TableRow>
    );
}

function ContractTransactionRow({
    transaction,
    onRowClick,
}: {
    transaction: ContractTransactionEntry;
    onRowClick: (transactionType: TransactionType, id: string) => void;
}) {
    return (
        <TableRow
            hover
            sx={{ cursor: 'pointer' }}
            onClick={() =>
                onRowClick(transaction.type, transaction.contractId.toString())
            }
        >
            <TableCellLayout firstCell>
                <PresenceWithTooltip
                    icon={<CompletedTransactionIcon />}
                    title={<ExitedTranslation />}
                />
            </TableCellLayout>
            <TableCellLayout>
                <BarrierGateIdentificationEntityDisplay
                    identificationEntity={
                        transaction.barrierGateIdentificationEntity
                    }
                />
            </TableCellLayout>
            <TableCellLayout>
                <ParkingDescription transaction={transaction} />
            </TableCellLayout>
            <TableCellLayout>
                <ValidFromValidToDisplay
                    validFrom={transaction.validFrom}
                    validTo={transaction.validTo}
                />
            </TableCellLayout>
            <TableCellLayout>
                <NicerPaymentChannel
                    paymentChannel={transaction.paymentChannel}
                />
            </TableCellLayout>
            <TableCellLayout>{transaction.duration}</TableCellLayout>
            <TableCellLayout lastCell>
                {`CHF ${transaction.amount.toFixed(2)} ${transaction.isRefunded ? '(R)' : ''}`}
            </TableCellLayout>
        </TableRow>
    );
}

function ValidFromValidToDisplay({
    validFrom,
    validTo,
}: {
    validFrom: string;
    validTo: string;
}) {
    return (
        <>
            {Formatter.dayMonthYearHourMinute(DateTime.fromISO(validFrom))}
            {' / '}
            {isSameDay(validFrom, validTo) ? (
                <>{Formatter.hourMinute(DateTime.fromISO(validTo))}</>
            ) : (
                <>
                    {Formatter.dayMonthYearHourMinute(
                        DateTime.fromISO(validTo),
                    )}
                </>
            )}
        </>
    );
}

function isSameDay(validFrom: string, validTo: string) {
    return (
        DateTime.fromISO(validFrom).toISODate() ===
        DateTime.fromISO(validTo).toISODate()
    );
}

function TranslationForEntryWithoutExitState({
    entryWithoutExitState,
}: {
    entryWithoutExitState: EntryWithoutExitState;
}) {
    switch (entryWithoutExitState) {
        case EntryWithoutExitState.COMPLETE:
            return <ExitedTranslation />;
        case EntryWithoutExitState.RUNNING:
            return <InTheParkingTranslation />;
        case EntryWithoutExitState.RUNNING_WITH_ANTIPASSBACK:
            return (
                <Localized
                    de="Im Parking (Antipassback aktiv)"
                    fr="Dans le parking (anti-passback actif)"
                    it="Nel parcheggio (anti-passback attivo)"
                    en="In the parking (anti-passback active)"
                />
            );
    }
}

function InTheParkingTranslation() {
    return (
        <Localized
            de="Im Parking"
            fr="Sur le parking"
            it="Nel parcheggio"
            en="In the parking"
        />
    );
}

function ExitedTranslation() {
    return <Localized de="Ausgefahren" fr="Quitté" it="Uscito" en="Exited" />;
}

function PendingTicketPaidDisplay({
    ticketState,
}: {
    ticketState: TicketState;
}): JSX.Element {
    switch (ticketState) {
        case TicketState.APPROVED:
            return (
                <PresenceWithTooltip
                    icon={
                        <Pending
                            style={{ color: Colors.green, fontSize: 'inherit' }}
                        />
                    }
                    title={<TranslationForTicketState state={ticketState} />}
                />
            );
        case TicketState.ALIAS_PENDING:
            return (
                <PresenceWithTooltip
                    icon={
                        <Error
                            style={{
                                color: Colors.yellow,
                                fontSize: 'inherit',
                            }}
                        />
                    }
                    title={<TranslationForTicketState state={ticketState} />}
                />
            );
        default:
            return <>{EmDash}</>;
    }
}

function IconForEntryWithoutExitState({
    entryWithoutExitState,
}: {
    entryWithoutExitState: EntryWithoutExitState;
}): JSX.Element {
    switch (entryWithoutExitState) {
        case EntryWithoutExitState.RUNNING:
            return <RunningTransactionIcon />;
        case EntryWithoutExitState.RUNNING_WITH_ANTIPASSBACK:
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RunningTransactionIcon />
                    <PanTool
                        sx={{
                            color: Colors.yellow,
                            fontSize: '24px',
                            margin: '2px',
                        }}
                    />
                </Box>
            );
        case EntryWithoutExitState.COMPLETE:
            return <CompletedTransactionIcon />;
    }
}

function CompletedTransactionIcon() {
    return <StopCircle style={{ color: Colors.grey, fontSize: 'inherit' }} />;
}

function RunningTransactionIcon() {
    return <PlayCircle sx={{ color: Colors.green, fontSize: 'inherit' }} />;
}

function TicketIdentificationDisplay({
    ticketApiString,
}: {
    ticketApiString: string;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <ConfirmationNumberIcon
                width={17}
                height={14}
                sx={{ paddingLeft: '3px' }}
            />
            <BarrierGateIdentificationString
                identificationType={BarrierGateIdentification.TICKET}
                identificationString={ticketApiString}
            />
        </Box>
    );
}

function BarrierGateIdentificationEntityDisplay({
    identificationEntity,
}: {
    identificationEntity: BarrierGateIdentificationEntity;
}): JSX.Element {
    switch (identificationEntity.identificationMedia) {
        case BarrierGateIdentification.BADGE:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BarrierGateIdentificationIcon
                        identificationType={BarrierGateIdentification.BADGE}
                        licensePlateType={null}
                    />
                    <BarrierGateIdentificationString
                        identificationType={BarrierGateIdentification.BADGE}
                        identificationString={identificationEntity.labelNr}
                    />
                </Box>
            );
        case BarrierGateIdentification.LICENSE_PLATE:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BarrierGateIdentificationIcon
                        identificationType={
                            BarrierGateIdentification.LICENSE_PLATE
                        }
                        licensePlateType={identificationEntity.licenseGroup}
                    />
                    <BarrierGateIdentificationString
                        identificationType={
                            BarrierGateIdentification.LICENSE_PLATE
                        }
                        identificationString={`${identificationEntity.licenseNumber} ${identificationEntity.countryCode}`}
                    />
                </Box>
            );
        case BarrierGateIdentification.QR_CODE:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BarrierGateIdentificationIcon
                        identificationType={BarrierGateIdentification.QR_CODE}
                        licensePlateType={null}
                    />
                    <BarrierGateIdentificationString
                        identificationType={
                            BarrierGateIdentification.LICENSE_PLATE
                        }
                        identificationString={identificationEntity.qrCodeId}
                    />
                </Box>
            );
        case BarrierGateIdentification.TICKET:
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BarrierGateIdentificationIcon
                        identificationType={BarrierGateIdentification.TICKET}
                        licensePlateType={null}
                    />
                    <BarrierGateIdentificationString
                        identificationType={BarrierGateIdentification.TICKET}
                        identificationString={
                            identificationEntity.ticketApiString
                        }
                    />
                </Box>
            );
    }
}

export function BarrierGateIdentificationIcon({
    identificationType,
    licensePlateType,
}: {
    identificationType: BarrierGateIdentification;
    licensePlateType: LicensePlateType | null;
}) {
    return (
        <Box
            sx={{
                paddingLeft: '3px',
                width: '28px',
                height: '22px',
            }}
        >
            <SvgIcon
                icon={
                    <IdentificationIcon
                        identificationType={identificationType}
                        licensePlateType={licensePlateType}
                    />
                }
                width={17}
                height={17}
            />
        </Box>
    );
}

export function BarrierGateIdentificationString({
    identificationType,
    identificationString,
}: {
    identificationType: BarrierGateIdentification;
    identificationString: string;
}): JSX.Element {
    switch (identificationType) {
        case BarrierGateIdentification.TICKET:
        case BarrierGateIdentification.QR_CODE:
        case BarrierGateIdentification.LICENSE_PLATE:
            return (
                <Box sx={{ paddingLeft: '5px' }}>{identificationString}</Box>
            );
        case BarrierGateIdentification.BADGE:
            return (
                <Box sx={{ paddingLeft: '5px' }}>
                    {addSpacesEveryFourthFromEnd(identificationString)}
                </Box>
            );
    }
}

export function addSpacesEveryFourthFromEnd(input: string): string {
    return input.replace(/(.)(?=(.{4})+$)/g, '$1 ');
}

function ParkingDescription({
    transaction,
}: {
    transaction: OffstreetTransactionEntry;
}): JSX.Element {
    return (
        <>
            {transaction.zone} {EmDash} {transaction.city}
        </>
    );
}

function PresenceWithTooltip({
    title,
    icon,
}: {
    title: JSX.Element;
    icon: JSX.Element;
}) {
    return (
        <Tooltip title={title}>
            <Box sx={{ display: 'flex' }}>
                {' '}
                {/* needed for the tooltip to work... */}
                <Box sx={{ fontSize: '28px', display: 'flex' }}>{icon}</Box>
            </Box>
        </Tooltip>
    );
}

export function OffstreetTransactionsTitle({
    offstreetTransactionsType,
}: {
    offstreetTransactionsType: ExistingOffstreetTransactionsType;
}): JSX.Element {
    switch (offstreetTransactionsType) {
        case ExistingOffstreetTransactionsType.TICKET:
            return (
                <Localized
                    de="Tickets"
                    fr="Tickets"
                    it="Tickets"
                    en="Tickets"
                />
            );
        case ExistingOffstreetTransactionsType.OFFSTREET_TRANSACTIONS:
            return (
                <Localized
                    de="Parkvorgänge"
                    fr="Procédures"
                    it="Procedure"
                    en="Procedures"
                />
            );
        case ExistingOffstreetTransactionsType.TICKET_AND_OFFSTREET_TRANSACTIONS:
            return (
                <Localized
                    de="Parkvorgänge & Tickets"
                    fr="Procédures & Tickets"
                    it="Procedure & Tickets"
                    en="Procedures & Tickets"
                />
            );
    }
}

export function NicerPaymentChannel({
    paymentChannel,
}: {
    paymentChannel: PaymentChannel;
}): JSX.Element {
    switch (paymentChannel) {
        case PaymentChannel.COOP:
            return <Localized de="Coop" fr="Coop" it="Coop" en="Coop" />;
        case PaymentChannel.EASYPARK:
            return (
                <Localized
                    de="EasyPark"
                    fr="EasyPark"
                    it="EasyPark"
                    en="EasyPark"
                />
            );
        case PaymentChannel.PARKINGPAY:
            return (
                <Localized
                    de="Parkingpay"
                    fr="Parkingpay"
                    it="Parkingpay"
                    en="Parkingpay"
                />
            );
        case PaymentChannel.TWINT:
            return <Localized de="TWINT" fr="TWINT" it="TWINT" en="TWINT" />;
        case PaymentChannel.SWISSPASS:
            return (
                <Localized
                    de="SwissPass"
                    fr="SwissPass"
                    it="SwissPass"
                    en="SwissPass"
                />
            );
        case PaymentChannel.TCS:
            return <Localized de="TCS" fr="TCS" it="TCS" en="TCS" />;
        case PaymentChannel.PARK_AND_RAIL_WEBVIEW:
            return (
                <Localized de="P+Rail" fr="P+Rail" it="P+Rail" en="P+Rail" />
            );
        case PaymentChannel.PARKINGABO:
        case PaymentChannel.PARKINGABO_COUNTER:
            return (
                <Localized
                    de="Parkingabo"
                    fr="Parkingabo"
                    it="Parkingabo"
                    en="Parkingabo"
                />
            );
        case PaymentChannel.QUICKCHECKOUT:
            return (
                <Localized
                    de="Quick-Checkout"
                    fr="Quick-Checkout"
                    it="Quick-Checkout"
                    en="Quick-Checkout"
                />
            );
        case PaymentChannel.PARKINGPORTAL_COUNTER:
            return (
                <Localized
                    de="Parkingportal"
                    fr="Parkingportal"
                    it="Parkingportal"
                    en="Parkingportal"
                />
            );
        case PaymentChannel.FZAG_PORTAL:
            return (
                <Localized
                    de="Flughafen"
                    fr="Flughafen"
                    it="Flughafen"
                    en="Flughafen"
                />
            );
        case PaymentChannel.PAY_STATION:
            return (
                <Localized de="Kasse" fr="Caisse" it="Cassa" en="Pay station" />
            );
    }
}

function TableCellLayout({
    children,
    firstCell,
    lastCell,
}: {
    children: JSX.Element | string;
    firstCell?: boolean;
    lastCell?: boolean;
}) {
    return (
        <TableCell
            sx={{
                paddingLeft: firstCell ? '24px' : '12px',
                paddingRight: lastCell ? '24px' : '12px',
            }}
        >
            {children}
        </TableCell>
    );
}
