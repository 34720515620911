import { Box } from '@mui/material';
import { Legal } from 'dg-web-shared/lib/legal/legal_module_imports.ts';
import { ParkingPortalFooter } from '../mobile/layout/ParkingPortalFooter.tsx';
import ReactMarkdown from 'react-markdown';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';

export function ParkingportalPrivacy() {
    const language = useOperatorLanguage();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'auto',
                }}
            >
                <ReactMarkdown>
                    {Legal.privacyPolicyMarkdown[language]}
                </ReactMarkdown>
            </Box>
            <ParkingPortalFooter />
        </Box>
    );
}
