import { MaterialHeader } from '../layout/components/MaterialHeader.tsx';
import { MaterialTitle } from '../layout/components/MaterialTitle.tsx';
import { MainContent } from '../shared-mui-components/MainContent.tsx';
import { CheckCircleOutline, GroupWork } from '@mui/icons-material';
import { Localized } from '../common/components/Localized.tsx';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { makeLocalizedText, Message } from 'dg-web-shared/lib/Localized.ts';
import {
    AppMode,
    TenantAllowedBarrierGateVehicleIdentification,
    TenantAllowedEnforcedVehicleIdentification,
    TenantPaymentMode,
} from 'dg-web-shared/model/TenantEnums.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import {
    appAndPaymentModeText,
    barrierGateIdentificationText,
} from 'product-shared/tenant/TenantTexts.ts';
import { useNavigate } from 'react-router-dom';
import { SeparationBar } from '../layout/components/SeparationBar.tsx';
import { EmptyResults } from '../shared-mui-components/ListMessages.tsx';
import { localeCompare } from 'dg-web-shared/lib/Sort.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';

interface TenantListItem {
    tenantId: number;
    description: Message;
    appMode: AppMode;
    paymentMode: TenantPaymentMode;
    activeUserCount: number;
    allowedEnforcedVehicleIdentification: TenantAllowedEnforcedVehicleIdentification | null;
    allowedBarrierGateVehicleIdentification: TenantAllowedBarrierGateVehicleIdentification | null;
}

export function OperatorUserCategories() {
    const [TenantResultState] = useServerFetch<TenantListItem[], object>(
        () => ({
            url: `/ui-api/operator-account/parkingabo/tenant-list`,
        }),
        {},
    );
    const language = useOperatorLanguage();

    return (
        <MainContent>
            <MaterialHeader editMode={false}>
                <MaterialTitle
                    Icon={GroupWork}
                    title={
                        <Localized
                            de="Betriebsarten"
                            fr="Modalités de gestion"
                            it="Modalità di gestione"
                            en="Operating modes"
                        />
                    }
                />
            </MaterialHeader>
            <SeparationBar />
            <OperatorAsyncLoadedSection
                requestState={TenantResultState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                render={tenants => {
                    if (tenants.length === 0) {
                        return <EmptyResults hasFilter={false} />;
                    }
                    return (
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Bezeichnung"
                                                fr="Description"
                                                it="Descrizione"
                                                en="Description"
                                            />
                                        </TableCell>
                                        <TableCell>Onstreet</TableCell>
                                        <TableCell>Offstreet</TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Modus"
                                                fr="Mode"
                                                it="Modo"
                                                en="Mode"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Anzahl Benutzer"
                                                fr="Nombre d'utilisateurs"
                                                it="Numero utenti"
                                                en="Number of users"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tenants
                                        .sort((a, b) =>
                                            sortByDescription(a, b, language),
                                        )
                                        .map(tenant => (
                                            <OperatorUserCategoryRow
                                                key={tenant.tenantId}
                                                tenant={tenant}
                                            />
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            />
        </MainContent>
    );
}

function OperatorUserCategoryRow({ tenant }: { tenant: TenantListItem }) {
    const navigate = useNavigate();
    return (
        <TableRow>
            <TableCell>
                <Localized {...tenant.description} />
            </TableCell>
            <TableCell>
                {tenant.allowedEnforcedVehicleIdentification != null && (
                    <CheckCircleOutline />
                )}
            </TableCell>
            <TableCell>
                {tenant.allowedBarrierGateVehicleIdentification != null && (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <CheckCircleOutline />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '4px',
                            }}
                        >
                            <Localized
                                {...barrierGateIdentificationText(
                                    tenant.allowedBarrierGateVehicleIdentification,
                                )}
                            />
                        </Box>
                    </Box>
                )}
            </TableCell>
            <TableCell>
                <Localized
                    {...appAndPaymentModeText(
                        tenant.appMode,
                        tenant.paymentMode,
                    )}
                />
            </TableCell>
            <TableCell>
                <Box
                    sx={{
                        flexDirection: 'row',
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            display: 'flex',
                            flexShrink: 1,
                        }}
                        onClick={() =>
                            navigate(
                                `/parkingabo/users?STATUS=ACTIVE%2CLOCKED%2CPENDING&TENANT=${tenant.tenantId}`,
                            )
                        }
                    >
                        {tenant.activeUserCount}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                        }}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
}

function sortByDescription(
    a: TenantListItem,
    b: TenantListItem,
    language: Language,
) {
    const t = makeLocalizedText(language);
    return localeCompare(t(a.description), t(b.description), true);
}
