import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Person } from '@mui/icons-material';
import { MaterialBar } from '../layout/components/MaterialBar.tsx';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    useTheme,
} from '@mui/material';
import {
    BlockLabel,
    FormContentLayout,
    FormLeftColumn,
    FormRightColumn,
} from '../layout/components/form/FormLayout.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { DateTime } from 'luxon';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import InfoIcon from '@mui/icons-material/Info';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { useNavigate, useParams } from 'react-router-dom';
import {
    RequestMethod,
    RequestStatus,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { OperatorAsyncLoadedSection } from '../app/components/OperatorAsyncLoadedSection.tsx';
import {
    languageFromString,
    makeLocalizedText,
    Message,
} from 'dg-web-shared/lib/Localized.ts';
import { EmDash } from 'dg-web-shared/lib/Punctuation.ts';
import { useOperatorLanguage } from '../common/state/SettingsState.ts';
import {
    generateProductStateText,
    OperatorProductStatus,
} from './OperatorProductState.tsx';
import React, { useEffect, useState } from 'react';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { getOrElse } from 'dg-web-shared/lib/MaybeV2.ts';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LuxonDateStringFormats } from 'dg-web-shared/lib/LuxonDateStringFormats.ts';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { OperatorParkingaboUsersProductsTenantRemarkLabel } from '../parkingabo-users/OperatorParkingaboUsersProductsAdd.tsx';
import { TenantPaymentMode } from 'dg-web-shared/model/TenantEnums.ts';
import { OperatorRoutedModalContent } from '../layout/components/OperatorRoutedModalContent.tsx';
import { HeaderComponent } from '../layout/components/HeaderComponent.tsx';
import { HeaderCancelSaveButtons } from '../layout/components/HeaderCancelSaveButtons.tsx';
import { HeaderActionCloseButtons } from '../layout/components/HeaderActionCloseButtons.tsx';
import {
    ModalVariant,
    OperatorRoutedModal,
} from '../ui/modal/OperatorRoutedModal.tsx';

export type ProductDetailsType =
    | ProductDetailSubscription
    | ProductDetailReservation
    | ProductDetailPermitUntilRevocation
    | ProductDetailPermitFixedDuration
    | AllAccessBarrierGate;

export interface ProductDetailReservation extends ProductDetails {
    bookedAt: string | null;
    refund: ReservationRefund | null;
    productType:
        | ParkingaboProductType.RESERVATION
        | ParkingaboProductType.GUEST_RESERVATION;
}

export interface ProductDetailSubscription extends ProductDetails {
    productType: ParkingaboProductType.SUBSCRIPTION;
    bookingData: ProductSubscriptionBooking | null;
    refundData: SubsriptionRefundInfo | null;
}

export interface ProductDetailPermitUntilRevocation extends ProductDetails {
    productType: ParkingaboProductType.PERMIT_UNTIL_REVOCATION;
}

export interface ProductDetailPermitFixedDuration extends ProductDetails {
    productType: ParkingaboProductType.PERMIT_FREE_FIXED_DURATION;
}

export interface AllAccessBarrierGate extends ProductDetails {
    productType: ParkingaboProductType.ALL_ACCESS_BARRIER_GATE;
}

interface ProductDetails {
    data: ProductDetailsData;
    productType: ParkingaboProductType;
}

interface ProductDetailsData {
    contractId: number;
    contractTemplateId: number;
    createdAt: string;
    priceRappen: number | null;
    productState: OperatorProductStatus;
    productName: Message;
    productOwnedBy: string;
    productOwnerNr: string;
    validFrom: string;
    validTo: string | null;
    productParkingList: string[];
    isStornoBlocked: StornoBlockedReason | null;
    isTerminateBlocked: RefundBlockedReason | null;
    isRefundBlocked: RefundBlockedReason | null;
    isReactivateBlocked: ReactivateBlockedReason | null;
    productRemark: string | null;
    tenantRemark: string | null;
    productTemplatePaymentModes: TenantPaymentMode[];
}

const enum StornoBlockedReason {
    MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
    ALREADY_PURCHASED = 'ALREADY_PURCHASED',
    WRONG_PRODUCT_STATE = 'WRONG_PRODUCT_STATE',
}

const enum ReactivateBlockedReason {
    MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
    WRONG_PRODUCT_STATE = 'WRONG_PRODUCT_STATE',
    WRONG_PRODUCT_TYPE = 'WRONG_PRODUCT_TYPE',
    PAST_END_DATE = 'PAST_END_DATE',
    NOT_YET_TERMINATED = 'NOT_YET_TERMINATED',
}

const enum RefundBlockedReason {
    MISSING_PERMISSIONS = 'MISSING_PERMISSIONS',
    ALREADY_REFUNDED = 'ALREADY_REFUNDED',
    WRONG_PRODUCT_TYPE = 'WRONG_PRODUCT_TYPE',
    NO_ACTIVE_ACCOUNT_TO_REFUND = 'NO_ACTIVE_ACCOUNT_TO_REFUND',
    PAST_REFUND_TIME = 'PAST_REFUND_TIME',
    NON_POSITIVE_PRICE = 'NON_POSITIVE_PRICE',
    NO_DIGITAL_PURCHASE = 'NO_DIGITAL_PURCHASE',
    WRONG_PRODUCT_STATE = 'WRONG_PRODUCT_STATE',
    NO_SUBSCRIPTION_CHARGE = 'NO_SUBSCRIPTION_CHARGE',
}

interface ReservationRefund {
    refundAmountRappen: number;
    refundedAt: string;
    refundedBy: string;
    remark: string;
}

interface ProductSubscriptionBooking {
    totalAmountRappen: number;
    mostRecentAmountRappen: number;
    mostRecentBookedAt: string;
    count: number;
}

interface SubsriptionRefundInfo {
    totalAmountRappen: number;
    mostRecentAmountRappen: number;
    mostRecentRefundedAt: string;
    mostRecentRefundedBy: string;
    mostRecentRemark: string;
    count: number;
}

export const enum ParkingaboProductType {
    RESERVATION = 'RESERVATION',
    GUEST_RESERVATION = 'GUEST_RESERVATION',
    SUBSCRIPTION = 'SUBSCRIPTION',
    PERMIT_UNTIL_REVOCATION = 'PERMIT_UNTIL_REVOCATION',
    PERMIT_FREE_FIXED_DURATION = 'PERMIT_FREE_FIXED_DURATION',
    ALL_ACCESS_BARRIER_GATE = 'ALL_ACCESS_BARRIER_GATE',
}

export function OperatorProductDetailModal({
    backUrl,
    onProductChange,
}: {
    backUrl: string;
    onProductChange: () => void;
}) {
    const urlParams = useParams<{ contractId: string }>();
    const contractId = urlParams.contractId
        ? parseInt(urlParams.contractId, 10)
        : null;
    const allowedProductTypes =
        useOperatorContext().currentLogin.allowedProductTypes;
    const [productDetailState, refetchProductDetail] = useServerFetch<
        ProductDetailsType,
        { contractId: number }
    >(
        context => ({
            url: `/ui-api/operator-account/product/${context.contractId}`,
        }),
        contractId ? { contractId } : null,
    );
    const navigate = useNavigate();

    if (!contractId) {
        return null;
    }

    return (
        <OperatorRoutedModal
            variant={ModalVariant.STANDARD}
            backUrl={backUrl}
            render={controller => (
                <OperatorAsyncLoadedSection
                    requestState={productDetailState}
                    render={productDetail => {
                        const hasPermission = allowedProductTypes.includes(
                            productDetail.data.contractTemplateId,
                        );
                        if (hasPermission) {
                            return (
                                <OperatorProductDetail
                                    productDetail={productDetail}
                                    onProductChange={() => {
                                        onProductChange && onProductChange();
                                        refetchProductDetail();
                                    }}
                                    backUrl={backUrl}
                                    onDirtyStateChange={
                                        controller.setShouldConfirmBeforeLeave
                                    }
                                    controlledNavigate={
                                        controller.navigateWithinModal
                                    }
                                />
                            );
                        } else {
                            return (
                                <Dialog open={true}>
                                    <DialogTitle>
                                        <Localized
                                            de="Fehlende Zugriffsrechte"
                                            fr="Droits d'accès insuffisants"
                                            it="Diritti d'accesso insufficienti"
                                            en="Insufficient access rights"
                                        />
                                    </DialogTitle>
                                    <DialogContent>
                                        <p>
                                            <Localized
                                                de="Sie haben nicht die erforderlichen Zugriffsrechte, um die Details zu diesem Produkt anzuzeigen."
                                                fr="Vous n'avez pas les droits d'accès nécessaires pour voir les détails de ce produit."
                                                it="Non dispone dei diritti d’accesso necessari per visualizzare i dettagli di questo prodotto."
                                                en="You do not have the necessary access rights to view the details of this product."
                                            />
                                        </p>
                                        <p>
                                            <Localized
                                                de="Bitte wenden Sie sich an den Parkingportal-Administrator in Ihrem Unternehmen."
                                                fr="Veuillez contacter l'administrateur Parkingportal de votre entreprise."
                                                it="Contatti l’amministratore Parkingportal presso la sua azienda."
                                                en="Please contact the Parkingportal administrator at your company."
                                            />
                                        </p>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => navigate(backUrl)}
                                        >
                                            <Localized
                                                de="Schliessen"
                                                fr="Fermer"
                                                it="Chiudi"
                                                en="Close"
                                            />
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            );
                        }
                    }}
                />
            )}
        />
    );
}

function MonthlySubscriptionPrice({ priceRappen }: { priceRappen: number }) {
    return (
        <Localized
            de={`${currencyCentsToLocalPrice('de', priceRappen)} pro Monat`}
            fr={`${currencyCentsToLocalPrice('fr', priceRappen)} par mois`}
            it={`${currencyCentsToLocalPrice('it', priceRappen)} al mese`}
            en={`${currencyCentsToLocalPrice('en', priceRappen)} per month`}
        />
    );
}

type RefundPayload = ReservationRefundPayload | SubscriptionRefundPayload;

interface ReservationRefundPayload {
    contractId: number;
    remark: string;
}

interface SubscriptionRefundPayload extends ReservationRefundPayload {
    subscriptionRefundAmountRappen: number | null;
}

type TerminatePayload =
    | ReservationTerminatePayload
    | SubscriptionTerminatePayload
    | PermitUntilRevocationTerminatePayload
    | PermitFixedDurationTerminatePayload;

interface ReservationTerminatePayload {
    contractId: number;
    reservationRefundRemark: string;
}

interface SubscriptionTerminatePayload {
    contractId: number;
    terminationDate: DateTime;
}

interface PermitUntilRevocationTerminatePayload {
    contractId: number;
    terminationDate: DateTime;
}

interface PermitFixedDurationTerminatePayload {
    contractId: number;
    terminationDate: DateTime;
}

function OperatorProductDetail({
    productDetail,
    onProductChange,
    backUrl,
    onDirtyStateChange,
    controlledNavigate,
}: {
    productDetail: ProductDetailsType;
    onProductChange: () => void;
    backUrl: string;
    onDirtyStateChange: (shouldConfirm: boolean) => void;
    controlledNavigate: (url: string) => void;
}) {
    const navigate = useNavigate();
    const language = useOperatorLanguage();

    const [showStornoDialog, setShowStornoDialog] = useState(false);
    const [stornoRequestState, stornoProduct] = useServerWrite<
        { contractId: number },
        null,
        ValidationData
    >(context => ({
        url: `/ui-api/operator-account/product/${context.contractId}/storno`,
    }));
    useServerSuccessEffect(stornoRequestState, () => {
        onProductChange();
    });

    const [showTerminateDialog, setShowTerminateDialog] = useState(false);
    const [terminateRequestState, terminateProduct] = useServerWrite<
        TerminatePayload,
        null,
        ValidationData
    >(context => ({
        url: `/ui-api/operator-account/product/${context.contractId}/terminate`,
    }));
    useServerSuccessEffect(terminateRequestState, () => {
        onProductChange();
    });

    const [showRefundDialog, setShowRefundDialog] = useState(false);
    const [refundRequestState, refundProduct] = useServerWrite<
        RefundPayload,
        null,
        ValidationData
    >(context => ({
        url: `/ui-api/operator-account/product/${context.contractId}/refund`,
    }));
    useServerSuccessEffect(refundRequestState, () => {
        onProductChange();
    });

    const [showReactivateDialog, setShowReactivateDialog] = useState(false);
    const [reactivateRequestState, reactivateProduct] = useServerWrite<
        { contractId: number },
        null,
        ValidationData
    >(context => ({
        url: `/ui-api/operator-account/product/${context.contractId}/reactivate`,
    }));
    useServerSuccessEffect(reactivateRequestState, () => {
        onProductChange();
    });

    const [updateTenantRemarkRequestState, updateTenantRemark] = useServerWrite<
        { contractId: number; tenantRemark: string | null },
        null,
        ValidationData
    >(context => ({
        url: `/ui-api/operator-account/product/${context.contractId}/update-tenant-remark`,
        method: RequestMethod.PUT,
    }));
    useServerSuccessEffect(updateTenantRemarkRequestState, () => {
        onProductChange();
    });
    const tenantRemark = productDetail.data.tenantRemark ?? '';
    const [currentTenantRemark, setCurrentTenantRemark] =
        useState(tenantRemark);
    const isDirty = currentTenantRemark != tenantRemark;
    useEffect(() => {
        onDirtyStateChange(isDirty);
    }, [isDirty]);

    const actionIsLoading =
        stornoRequestState.status === RequestStatus.PENDING ||
        terminateRequestState.status === RequestStatus.PENDING ||
        refundRequestState.status === RequestStatus.PENDING ||
        reactivateRequestState.status === RequestStatus.PENDING;

    return (
        <OperatorRoutedModalContent
            header={
                <HeaderComponent
                    title={<Localized {...productDetail.data.productName} />}
                    Icon={AssignmentIndIcon}
                    editMode={isDirty}
                    buttons={
                        isDirty ? (
                            <HeaderCancelSaveButtons
                                onCancel={() => {
                                    setCurrentTenantRemark(tenantRemark);
                                }}
                                onSave={() =>
                                    updateTenantRemark({
                                        contractId:
                                            productDetail.data.contractId,
                                        tenantRemark:
                                            currentTenantRemark != ''
                                                ? currentTenantRemark
                                                : null,
                                    })
                                }
                                loading={
                                    updateTenantRemarkRequestState.status ===
                                    RequestStatus.PENDING
                                }
                            />
                        ) : (
                            <HeaderActionCloseButtons
                                onClose={() => navigate(backUrl)}
                                isLoading={actionIsLoading}
                                dropdownItems={[
                                    {
                                        key: 'storno',
                                        label: (
                                            <Localized
                                                de="Löschen"
                                                fr="Supprimer"
                                                it="Cancella"
                                                en="Delete"
                                            />
                                        ),
                                        tooltip: productDetail.data
                                            .isStornoBlocked ? (
                                            <StornoTooltip
                                                isStornoBlocked={
                                                    productDetail.data
                                                        .isStornoBlocked
                                                }
                                            />
                                        ) : undefined,
                                        disabled:
                                            !!productDetail.data
                                                .isStornoBlocked,
                                        onClickCallback: () =>
                                            setShowStornoDialog(true),
                                    },
                                    {
                                        key: 'terminate',
                                        label: (
                                            <Localized
                                                de="Beenden"
                                                fr="Terminer"
                                                it="Termina"
                                                en="Terminate"
                                            />
                                        ),
                                        tooltip: productDetail.data
                                            .isTerminateBlocked ? (
                                            <TerminateRefundTooltip
                                                blockedReason={
                                                    productDetail.data
                                                        .isTerminateBlocked
                                                }
                                                productType={
                                                    productDetail.productType
                                                }
                                                type="terminate"
                                            />
                                        ) : undefined,
                                        disabled:
                                            !!productDetail.data
                                                .isTerminateBlocked,
                                        onClickCallback: () =>
                                            setShowTerminateDialog(true),
                                    },
                                    {
                                        key: 'reactivate',
                                        label: (
                                            <Localized
                                                de="Reaktivieren"
                                                fr="Réactiver"
                                                it="Riattiva"
                                                en="Reactivate"
                                            />
                                        ),
                                        tooltip: productDetail.data
                                            .isReactivateBlocked ? (
                                            <ReactivateTooltip
                                                isReactivateBlocked={
                                                    productDetail.data
                                                        .isReactivateBlocked
                                                }
                                            />
                                        ) : undefined,
                                        disabled:
                                            !!productDetail.data
                                                .isReactivateBlocked,
                                        onClickCallback: () =>
                                            setShowReactivateDialog(true),
                                    },
                                    { key: 'divider', divider: true },
                                    {
                                        key: 'refund',
                                        label: (
                                            <Localized
                                                de="Zurückerstatten"
                                                fr="Rembourser"
                                                it="Rimborsa"
                                                en="Refund"
                                            />
                                        ),
                                        tooltip: productDetail.data
                                            .isRefundBlocked ? (
                                            <TerminateRefundTooltip
                                                blockedReason={
                                                    productDetail.data
                                                        .isRefundBlocked
                                                }
                                                productType={
                                                    productDetail.productType
                                                }
                                                type="refund"
                                            />
                                        ) : undefined,
                                        disabled:
                                            !!productDetail.data
                                                .isRefundBlocked,
                                        onClickCallback: () =>
                                            setShowRefundDialog(true),
                                    },
                                ]}
                            />
                        )
                    }
                />
            }
            bar={
                <MaterialBar
                    title={productDetail.data.productOwnedBy}
                    onClick={() =>
                        controlledNavigate(
                            `/parkingabo/users/${productDetail.data.productOwnerNr}/detail`,
                        )
                    }
                    Icon={Person}
                />
            }
            body={
                <>
                    <StornoDialog
                        open={showStornoDialog}
                        onStorno={() => {
                            setShowStornoDialog(false);
                            stornoProduct({
                                contractId: productDetail.data.contractId,
                            });
                        }}
                        onCancel={() => setShowStornoDialog(false)}
                        productTemplatePaymentModes={
                            productDetail.data.productTemplatePaymentModes
                        }
                    />
                    <TerminateDialog
                        open={showTerminateDialog}
                        onTerminate={payload => {
                            setShowTerminateDialog(false);
                            terminateProduct(payload);
                        }}
                        onCancel={() => setShowTerminateDialog(false)}
                        productDetail={productDetail}
                    />
                    <RefundDialog
                        open={showRefundDialog}
                        onRefund={payload => {
                            setShowRefundDialog(false);
                            refundProduct(payload);
                        }}
                        onCancel={() => setShowRefundDialog(false)}
                        productDetail={productDetail}
                    />
                    <ReactivateDialog
                        open={showReactivateDialog}
                        onReactivate={() => {
                            setShowReactivateDialog(false);
                            reactivateProduct({
                                contractId: productDetail.data.contractId,
                            });
                        }}
                        onCancel={() => setShowReactivateDialog(false)}
                    />
                    <BackendRequestErrorMessage
                        requestState={stornoRequestState}
                    />
                    <BackendRequestErrorMessage
                        requestState={terminateRequestState}
                    />
                    <BackendRequestErrorMessage
                        requestState={refundRequestState}
                    />
                    <FormContentLayout>
                        <FormLeftColumn>
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Produkt-Typ"
                                        fr="Type de produit"
                                        it="Tipo prodotto"
                                        en="Product type"
                                    />
                                }
                                value={makeLocalizedText(
                                    languageFromString(language),
                                )(productDetail.data.productName)}
                            />
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Status"
                                        fr="État"
                                        it="Stato"
                                        en="State"
                                    />
                                }
                                value={makeLocalizedText(
                                    languageFromString(language),
                                )(
                                    generateProductStateText(
                                        productDetail.data.productState,
                                    ),
                                )}
                            />
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Beginn"
                                        fr="Début"
                                        it="Inizio"
                                        en="Start"
                                    />
                                }
                                value={Formatter.dayMonthYearHourMinute(
                                    DateTime.fromISO(
                                        productDetail.data.validFrom,
                                    ),
                                )}
                            />
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Ende"
                                        fr="Fin"
                                        it="Fine"
                                        en="End"
                                    />
                                }
                                value={
                                    productDetail.data.validTo
                                        ? Formatter.dayMonthYearHourMinute(
                                              DateTime.fromISO(
                                                  productDetail.data.validTo,
                                              ),
                                          )
                                        : EmDash
                                }
                            />
                            <TextField
                                disabled
                                label={
                                    <Localized
                                        de="Parking"
                                        fr="Parking"
                                        it="Parcheggio"
                                        en="Parking"
                                    />
                                }
                                multiline
                                rows={
                                    productDetail.data.productParkingList.length
                                }
                                value={
                                    productDetail.data.productParkingList
                                        .length > 0
                                        ? productDetail.data.productParkingList.join(
                                              '\n',
                                          )
                                        : EmDash
                                }
                            />
                        </FormLeftColumn>
                        <OperatorProductRightColumnContainer
                            productDetail={productDetail}
                            currentTenantRemark={currentTenantRemark}
                            setCurrentTenantRemark={setCurrentTenantRemark}
                        />
                    </FormContentLayout>
                </>
            }
        />
    );
}

function OperatorProductRightColumnContainer({
    productDetail,
    currentTenantRemark,
    setCurrentTenantRemark,
}: {
    productDetail: ProductDetailsType;
    currentTenantRemark: string | null;
    setCurrentTenantRemark: React.Dispatch<React.SetStateAction<string>>;
}) {
    return (
        <FormRightColumn>
            <OperatorProductRightColumn productDetail={productDetail} />
            <TextField
                multiline
                minRows={3}
                maxRows={3}
                label={<OperatorParkingaboUsersProductsTenantRemarkLabel />}
                value={currentTenantRemark}
                onChange={e => {
                    setCurrentTenantRemark(e.target.value);
                }}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                }}
            >
                <BlockLabel
                    label={
                        <Localized
                            de="Erstellt am"
                            fr="Créé le"
                            it="Creato il"
                            en="Created at"
                        />
                    }
                >
                    {Formatter.dayMonthYearHourMinute(
                        DateTime.fromISO(productDetail.data.createdAt),
                    )}
                </BlockLabel>
                <BlockLabel label="ID">
                    {productDetail.data.contractId}
                </BlockLabel>
            </Box>
        </FormRightColumn>
    );
}

function OperatorProductRightColumn({
    productDetail,
}: {
    productDetail: ProductDetailsType;
}): JSX.Element | null {
    switch (productDetail.productType) {
        case ParkingaboProductType.SUBSCRIPTION:
            return (
                <OperatorProductRightColumnSubscription
                    productDetail={productDetail}
                />
            );
        case ParkingaboProductType.RESERVATION:
        case ParkingaboProductType.GUEST_RESERVATION:
            return (
                <OperatorProductRightColumnReservation
                    productDetail={productDetail}
                />
            );
        case ParkingaboProductType.PERMIT_UNTIL_REVOCATION:
        case ParkingaboProductType.PERMIT_FREE_FIXED_DURATION:
        case ParkingaboProductType.ALL_ACCESS_BARRIER_GATE:
            return null;
    }
}

function OperatorProductRightColumnReservation({
    productDetail,
}: {
    productDetail: ProductDetailReservation;
}) {
    const theme = useTheme();
    return (
        <>
            <TextField
                disabled
                label={
                    <OperatorLabel
                        label={
                            <Localized
                                de="Preis"
                                fr="Prix"
                                it="Prezzo"
                                en="Price"
                            />
                        }
                    />
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {productDetail.data.priceRappen ? (
                                <Localized
                                    de={currencyCentsToLocalPrice(
                                        'de',
                                        productDetail.data.priceRappen,
                                    )}
                                    fr={currencyCentsToLocalPrice(
                                        'fr',
                                        productDetail.data.priceRappen,
                                    )}
                                    it={currencyCentsToLocalPrice(
                                        'it',
                                        productDetail.data.priceRappen,
                                    )}
                                    en={currencyCentsToLocalPrice(
                                        'en',
                                        productDetail.data.priceRappen,
                                    )}
                                />
                            ) : (
                                EmDash
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <Stack
                    spacing={1}
                    style={{
                        flexBasis: '50%',
                        paddingRight: theme.spacing(1),
                    }}
                >
                    <TextField
                        disabled
                        label={
                            <OperatorLabel
                                label={
                                    <Localized
                                        de="Belastung"
                                        fr="Débit"
                                        it="Addebito"
                                        en="Debit"
                                    />
                                }
                                infoText={
                                    productDetail.bookedAt &&
                                    productDetail.data.priceRappen ? (
                                        <PriceTooltip
                                            bookedAt={productDetail.bookedAt}
                                        />
                                    ) : undefined
                                }
                            />
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {productDetail.bookedAt &&
                                    productDetail.data.priceRappen ? (
                                        <Localized
                                            de={currencyCentsToLocalPrice(
                                                'de',
                                                productDetail.data.priceRappen,
                                            )}
                                            fr={currencyCentsToLocalPrice(
                                                'fr',
                                                productDetail.data.priceRappen,
                                            )}
                                            it={currencyCentsToLocalPrice(
                                                'it',
                                                productDetail.data.priceRappen,
                                            )}
                                            en={currencyCentsToLocalPrice(
                                                'en',
                                                productDetail.data.priceRappen,
                                            )}
                                        />
                                    ) : (
                                        EmDash
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                {productDetail.refund && (
                    <Stack
                        spacing={1}
                        style={{
                            flexBasis: '50%',
                            paddingLeft: theme.spacing(1),
                        }}
                    >
                        <TextField
                            disabled
                            label={
                                <OperatorLabel
                                    label={
                                        <Localized
                                            de="Rückerstattung"
                                            fr="Remboursement"
                                            it="Rimborso"
                                            en="Refund"
                                        />
                                    }
                                    infoText={
                                        productDetail.refund ? (
                                            <ReservationRefundTooltip
                                                refund={productDetail.refund}
                                            />
                                        ) : undefined
                                    }
                                />
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {productDetail.refund ? (
                                            <Localized
                                                de={currencyCentsToLocalPrice(
                                                    'de',
                                                    productDetail.refund
                                                        .refundAmountRappen,
                                                )}
                                                fr={currencyCentsToLocalPrice(
                                                    'fr',
                                                    productDetail.refund
                                                        .refundAmountRappen,
                                                )}
                                                it={currencyCentsToLocalPrice(
                                                    'it',
                                                    productDetail.refund
                                                        .refundAmountRappen,
                                                )}
                                                en={currencyCentsToLocalPrice(
                                                    'en',
                                                    productDetail.refund
                                                        .refundAmountRappen,
                                                )}
                                            />
                                        ) : (
                                            EmDash
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                )}
            </Box>
            {productDetail.productType ==
                ParkingaboProductType.GUEST_RESERVATION &&
                productDetail.data.productRemark != null && (
                    <TextField
                        disabled
                        label={
                            <Localized
                                de="Gastdaten"
                                fr="Données du visiteur"
                                it="Dati ospite"
                                en="Guest data"
                            />
                        }
                        value={productDetail.data.productRemark.replace(
                            /\n/g,
                            ' / ',
                        )}
                    />
                )}
        </>
    );
}

function OperatorProductRightColumnSubscription({
    productDetail,
}: {
    productDetail: ProductDetailSubscription;
}) {
    const theme = useTheme();
    return (
        <>
            <TextField
                disabled
                label={
                    <OperatorLabel
                        label={
                            <Localized
                                de="Preis"
                                fr="Prix"
                                it="Prezzo"
                                en="Price"
                            />
                        }
                    />
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {productDetail.data.priceRappen ? (
                                <MonthlySubscriptionPrice
                                    priceRappen={productDetail.data.priceRappen}
                                />
                            ) : (
                                EmDash
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                }}
            >
                <Stack
                    spacing={1}
                    style={{
                        flexBasis: '50%',
                        paddingRight: theme.spacing(1),
                    }}
                >
                    <TextField
                        disabled
                        label={
                            <OperatorLabel
                                label={
                                    <Localized
                                        de="Belastungen"
                                        fr="Débits"
                                        it="Addebiti"
                                        en="Debits"
                                    />
                                }
                                infoText={
                                    productDetail.bookingData ? (
                                        <SubscriptionTooltip
                                            amountRappen={
                                                productDetail.bookingData
                                                    .mostRecentAmountRappen
                                            }
                                            bookedAt={
                                                productDetail.bookingData
                                                    .mostRecentBookedAt
                                            }
                                            refundedBy={null}
                                            remark={null}
                                        />
                                    ) : undefined
                                }
                            />
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {productDetail.bookingData ? (
                                        <>
                                            <Localized
                                                de={`${currencyCentsToLocalPrice(
                                                    'de',
                                                    productDetail.bookingData
                                                        .totalAmountRappen,
                                                )}`}
                                                fr={`${currencyCentsToLocalPrice(
                                                    'fr',
                                                    productDetail.bookingData
                                                        .totalAmountRappen,
                                                )}`}
                                                it={`${currencyCentsToLocalPrice(
                                                    'it',
                                                    productDetail.bookingData
                                                        .totalAmountRappen,
                                                )}`}
                                                en={`${currencyCentsToLocalPrice(
                                                    'en',
                                                    productDetail.bookingData
                                                        .totalAmountRappen,
                                                )}`}
                                            />
                                            {` (${productDetail.bookingData.count})`}
                                        </>
                                    ) : (
                                        EmDash
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Stack
                    spacing={1}
                    style={{
                        flexBasis: '50%',
                        paddingLeft: theme.spacing(1),
                    }}
                >
                    {productDetail.refundData && (
                        <TextField
                            disabled
                            label={
                                <OperatorLabel
                                    label={
                                        <Localized
                                            de="Rückerstattungen"
                                            fr="Remboursements"
                                            it="Rimborsi"
                                            en="Refunds"
                                        />
                                    }
                                    infoText={
                                        productDetail.refundData ? (
                                            <SubscriptionTooltip
                                                amountRappen={
                                                    productDetail.refundData
                                                        .mostRecentAmountRappen
                                                }
                                                bookedAt={
                                                    productDetail.refundData
                                                        .mostRecentRefundedAt
                                                }
                                                refundedBy={
                                                    productDetail.refundData
                                                        .mostRecentRefundedBy
                                                }
                                                remark={
                                                    productDetail.refundData
                                                        .mostRecentRemark
                                                }
                                            />
                                        ) : undefined
                                    }
                                />
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {productDetail.refundData ? (
                                            <>
                                                <Localized
                                                    de={`${currencyCentsToLocalPrice(
                                                        'de',
                                                        productDetail.refundData
                                                            .totalAmountRappen,
                                                    )}`}
                                                    fr={`${currencyCentsToLocalPrice(
                                                        'fr',
                                                        productDetail.refundData
                                                            .totalAmountRappen,
                                                    )}`}
                                                    it={`${currencyCentsToLocalPrice(
                                                        'it',
                                                        productDetail.refundData
                                                            .totalAmountRappen,
                                                    )}`}
                                                    en={`${currencyCentsToLocalPrice(
                                                        'en',
                                                        productDetail.refundData
                                                            .totalAmountRappen,
                                                    )}`}
                                                />
                                                {` (${productDetail.refundData.count})`}
                                            </>
                                        ) : (
                                            EmDash
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </Stack>
            </Box>
        </>
    );
}

function PriceTooltip({ bookedAt }: { bookedAt: string | null }) {
    return (
        <>
            <b>
                {bookedAt
                    ? Formatter.dayMonthYearHourMinute(
                          DateTime.fromISO(bookedAt),
                      )
                    : EmDash}
            </b>
        </>
    );
}

function ReservationRefundTooltip({ refund }: { refund: ReservationRefund }) {
    return (
        <>
            <b>
                {Formatter.dayMonthYearHourMinute(
                    DateTime.fromISO(refund.refundedAt),
                )}
            </b>
            <br />
            <Localized
                de="Erfasst von"
                fr="Saisi par"
                it="Registrato da"
                en="Captured by"
            />
            : <b>{refund.refundedBy}</b>
            {refund.remark && (
                <>
                    <br />
                    <Localized
                        de="Bemerkung"
                        fr="Remarque"
                        it="Osservazione"
                        en="Remark"
                    />
                    : <b>{refund.remark}</b>
                </>
            )}
        </>
    );
}

function SubscriptionTooltip({
    amountRappen,
    bookedAt,
    refundedBy,
    remark,
}: {
    amountRappen: number;
    bookedAt: string;
    refundedBy: string | null;
    remark: string | null;
}) {
    return (
        <>
            <Localized
                de="Zuletzt am"
                fr="Dernière fois le"
                it="Ultima volta il"
                en="Last time on"
            />
            :{' '}
            <b>
                {Formatter.dayMonthYearHourMinute(DateTime.fromISO(bookedAt))}
            </b>
            <br />
            <Localized
                de="Betrag"
                fr="Montant"
                it="Importo"
                en="Amount"
            />:{' '}
            <Localized
                de={`${currencyCentsToLocalPrice('de', amountRappen)}`}
                fr={`${currencyCentsToLocalPrice('fr', amountRappen)}`}
                it={`${currencyCentsToLocalPrice('it', amountRappen)}`}
                en={`${currencyCentsToLocalPrice('en', amountRappen)}`}
            />
            {refundedBy && (
                <>
                    <br />
                    <Localized
                        de="Erfasst von"
                        fr="Saisi par"
                        it="Registrato da"
                        en="Captured by"
                    />
                    : <b>{refundedBy}</b>
                </>
            )}
            {remark && (
                <>
                    <br />
                    <Localized
                        de="Bemerkung"
                        fr="Remarque"
                        it="Osservazione"
                        en="Remark"
                    />
                    : <b>{remark}</b>
                </>
            )}
        </>
    );
}

function OperatorLabel({
    label,
    infoText,
}: {
    label: React.ReactNode;
    infoText?: React.ReactNode;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {label}
            {infoText && (
                <Tooltip title={infoText} placement="right">
                    <InfoIcon
                        sx={{
                            color: theme => theme.palette.blue.main,
                            marginLeft: 1,
                        }}
                    />
                </Tooltip>
            )}
        </Box>
    );
}

function StornoDialog({
    open,
    onCancel,
    onStorno,
    productTemplatePaymentModes,
}: {
    open: boolean;
    onCancel: () => void;
    onStorno: () => void;
    productTemplatePaymentModes: TenantPaymentMode[];
}) {
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="Produkt löschen"
                    fr="Supprimer le produit"
                    it="Cancella prodotto"
                    en="Delete product"
                />
            </DialogTitle>
            <DialogContent>
                {productTemplatePaymentModes.includes(
                    TenantPaymentMode.DIGITAL_PURCHASE,
                ) && (
                    <Localized
                        de="Es erfolgt keine Rückerstattung, da das Produkt noch nicht belastet wurde."
                        fr="Aucun remboursement n'aura lieu, car le produit n'a pas encore été débité."
                        it="Non avverrà alcun rimborso, in quanto il prodotto non é ancora stato addebitato."
                        en="No refund will be made as the product has not yet been charged."
                    />
                )}
                <p>
                    <Localized
                        de="Wollen Sie dieses Produkt wirklich löschen?"
                        fr="Voulez-vous vraiment supprimer ce produit?"
                        it="Vuole veramente cancellare questo prodotto?"
                        en="Do you really want to delete this product?"
                    />
                </p>
            </DialogContent>
            <DialogConfirmCancelActions
                onConfirm={onStorno}
                onCancel={onCancel}
                confirmDisabled={false}
            />
        </Dialog>
    );
}

function ReactivateDialog({
    open,
    onCancel,
    onReactivate,
}: {
    open: boolean;
    onCancel: () => void;
    onReactivate: () => void;
}) {
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="Produkt reaktivieren"
                    fr="Réactiver le produit"
                    it="Riattiva prodotto"
                    en="Reactivate product"
                />
            </DialogTitle>
            <DialogContent>
                <Localized
                    de="Möchten Sie dieses Produkt wirklich reaktivieren?"
                    fr="Voulez-vous vraiment réactiver ce produit?"
                    it="Vuole veramente riattivare questo prodotto?"
                    en="Do you really want to reactivate this product?"
                />
            </DialogContent>
            <DialogConfirmCancelActions
                onConfirm={onReactivate}
                onCancel={onCancel}
                confirmDisabled={false}
            />
        </Dialog>
    );
}

function TerminateDialog({
    open,
    onCancel,
    onTerminate,
    productDetail,
}: {
    open: boolean;
    onCancel: () => void;
    onTerminate: (payload: TerminatePayload) => void;
    productDetail: ProductDetailsType;
}): JSX.Element {
    switch (productDetail.productType) {
        case ParkingaboProductType.SUBSCRIPTION:
            return (
                <TerminateDialogSubscriptionProducts
                    open={open}
                    onCancel={onCancel}
                    onTerminate={onTerminate}
                    contractId={productDetail.data.contractId}
                />
            );
        case ParkingaboProductType.ALL_ACCESS_BARRIER_GATE:
        case ParkingaboProductType.PERMIT_UNTIL_REVOCATION:
        case ParkingaboProductType.PERMIT_FREE_FIXED_DURATION:
            return (
                <TerminateDialogPermitUntilRevocationProducts
                    open={open}
                    onCancel={onCancel}
                    onTerminate={onTerminate}
                    contractId={productDetail.data.contractId}
                />
            );
        case ParkingaboProductType.RESERVATION:
        case ParkingaboProductType.GUEST_RESERVATION:
            return (
                <TerminateDialogReservation
                    open={open}
                    onCancel={onCancel}
                    onTerminate={onTerminate}
                    contractId={productDetail.data.contractId}
                />
            );
    }
}

function TerminateDialogReservation({
    open,
    onCancel,
    onTerminate,
    contractId,
}: {
    open: boolean;
    onCancel: () => void;
    onTerminate: (payload: ReservationTerminatePayload) => void;
    contractId: number;
}) {
    const [remark, setRemark] = useState('');
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="Produkt beenden"
                    fr="Terminer le produit"
                    it="Termina prodotto"
                    en="Terminate product"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Localized
                        de={
                            <>
                                Die Gültigkeit des Produkts wird{' '}
                                <b>sofort beendet</b> und <b>der Gesamtpreis</b>{' '}
                                wird zurückbezahlt.
                            </>
                        }
                        fr={
                            <>
                                La validité du produit sera{' '}
                                <b>terminée immédiatement</b> et{' '}
                                <b>le prix total</b> sera remboursé.
                            </>
                        }
                        it={
                            <>
                                La validità del prodotto verrà{' '}
                                <b>termina immediatamente</b> e{' '}
                                <b>il prezzo totale</b> verrà rimborsato.
                            </>
                        }
                        en={
                            <>
                                The validity of the product will be{' '}
                                <b>immediately terminated</b> and{' '}
                                <b>the total price</b> will be refunded.
                            </>
                        }
                    />
                    <p>
                        <Localized
                            de="Möchten Sie dieses Produkt wirklich beenden?"
                            fr="Voulez-vous vraiment terminer ce produit?"
                            it="Vuole veramente terminare questo prodotto?"
                            en="Do you really want to terminate this product?"
                        />
                    </p>
                </DialogContentText>
                <TextField
                    autoFocus
                    sx={{ marginBottom: 3 }}
                    label={
                        <Localized
                            de="Bemerkung"
                            fr="Remarque"
                            it="Osservazione"
                            en="Remark"
                        />
                    }
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                />
                <DialogConfirmCancelActions
                    onConfirm={() =>
                        onTerminate({
                            contractId: contractId,
                            reservationRefundRemark: remark,
                        })
                    }
                    onCancel={onCancel}
                    confirmDisabled={false}
                />
            </DialogContent>
        </Dialog>
    );
}

function TerminateDialogSubscriptionProducts({
    open,
    onCancel,
    onTerminate,
    contractId,
}: {
    open: boolean;
    onCancel: () => void;
    onTerminate: (payload: SubscriptionTerminatePayload) => void;
    contractId: number;
}) {
    const [terminationDate, setTerminationDate] = useState<DateTime>(
        DateTime.now(),
    );
    const lastEndOfMonth = terminationDate
        .minus({ month: 1 })
        .endOf('month')
        .toFormat(LuxonDateStringFormats.DATE);
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="Produkt beenden"
                    fr="Terminer le produit"
                    it="Termina prodotto"
                    en="Terminate product"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    <p>
                        <Localized
                            de={
                                <>
                                    Wählen Sie{' '}
                                    <b>das Enddatum der Gültigkeit</b> des
                                    Produkts:
                                </>
                            }
                            fr={
                                <>
                                    Sélectionnez{' '}
                                    <b>la date de fin de validité</b> du
                                    produit:
                                </>
                            }
                            it={
                                <>
                                    Selezioni <b>la data di fine validità</b>{' '}
                                    del prodotto:
                                </>
                            }
                            en={
                                <>
                                    Select <b>the end date of the validity</b>{' '}
                                    of the product:
                                </>
                            }
                        />
                    </p>
                </DialogContentText>
                <DesktopDatePicker
                    format="dd.MM.yyyy"
                    value={terminationDate}
                    maxDate={DateTime.now().endOf('month').plus({ months: 3 })}
                    disablePast={true}
                    onChange={value =>
                        value !== null ? setTerminationDate(value) : {}
                    }
                    slotProps={{ textField: { size: 'medium' } }}
                />
                <DialogContentText component="div">
                    <p>
                        {terminationDate.day === terminationDate.daysInMonth ? (
                            <Localized
                                de={`Die letzte Belastung findet am gewählten Enddatum statt.`}
                                fr={`Le dernier débit aura lieu à la date de fin choisie.`}
                                it={`L'ultimo addebito avverrà alla data di fine selezionata.`}
                                en={`The last debit will be made on the chosen end date.`}
                            />
                        ) : terminationDate.day <
                              DateTime.local().daysInMonth &&
                          terminationDate.month === DateTime.local().month ? (
                            <b>
                                <Localized
                                    de="Das Produkt wird nicht mehr belastet."
                                    fr="Le produit ne sera plus facturé."
                                    it="Il prodotto non sarà più addebitato."
                                    en="The product will no longer be charged."
                                />
                            </b>
                        ) : (
                            <b>
                                <Localized
                                    de={`Die letzte Belastung findet am ${lastEndOfMonth} statt.`}
                                    fr={`Le dernier débit sera effectué le ${lastEndOfMonth}.`}
                                    it={`L'ultimo addebito avverrà il ${lastEndOfMonth}.`}
                                    en={`The last debit will occur on ${lastEndOfMonth}.`}
                                />
                            </b>
                        )}
                    </p>
                    <p>
                        <Localized
                            de="Möchten Sie dieses Produkt wirklich beenden?"
                            fr="Voulez-vous vraiment terminer ce produit?"
                            it="Vuole veramente terminare questo prodotto?"
                            en="Do you really want to terminate this product?"
                        />
                    </p>
                </DialogContentText>
                <DialogConfirmCancelActions
                    onConfirm={() =>
                        onTerminate({
                            contractId: contractId,
                            terminationDate: terminationDate,
                        })
                    }
                    onCancel={onCancel}
                    confirmDisabled={false}
                />
            </DialogContent>
        </Dialog>
    );
}

function TerminateDialogPermitUntilRevocationProducts({
    open,
    onCancel,
    onTerminate,
    contractId,
}: {
    open: boolean;
    onCancel: () => void;
    onTerminate: (payload: PermitUntilRevocationTerminatePayload) => void;
    contractId: number;
}) {
    const [terminationDate, setTerminationDate] = useState<DateTime>(
        DateTime.now(),
    );
    return (
        <Dialog open={open} maxWidth="xs" onClose={onCancel}>
            <DialogTitle>
                <Localized
                    de="Produkt beenden"
                    fr="Terminer le produit"
                    it="Termina prodotto"
                    en="Terminate product"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText component="div">
                    <p>
                        <Localized
                            de={
                                <>
                                    Wählen Sie{' '}
                                    <b>das Enddatum der Gültigkeit</b> des
                                    Produkts:
                                </>
                            }
                            fr={
                                <>
                                    Sélectionnez{' '}
                                    <b>la date de fin de validité</b> du
                                    produit:
                                </>
                            }
                            it={
                                <>
                                    Selezioni <b>la data di fine validità</b>{' '}
                                    del prodotto:
                                </>
                            }
                            en={
                                <>
                                    Select <b>the end date of the validity</b>{' '}
                                    of the product:
                                </>
                            }
                        />
                    </p>
                </DialogContentText>
                <DesktopDatePicker
                    format="dd.MM.yyyy"
                    value={terminationDate}
                    maxDate={DateTime.now().endOf('month').plus({ months: 3 })}
                    disablePast={true}
                    onChange={value =>
                        value !== null ? setTerminationDate(value) : {}
                    }
                    slotProps={{ textField: { size: 'medium' } }}
                />
                <DialogContentText component="div">
                    <p>
                        <Localized
                            de="Möchten Sie dieses Produkt wirklich beenden?"
                            fr="Voulez-vous vraiment terminer ce produit?"
                            it="Vuole veramente terminare questo prodotto?"
                            en="Do you really want to terminate this product?"
                        />
                    </p>
                </DialogContentText>
                <DialogConfirmCancelActions
                    onConfirm={() =>
                        onTerminate({
                            contractId: contractId,
                            terminationDate: terminationDate,
                        })
                    }
                    onCancel={onCancel}
                    confirmDisabled={false}
                />
            </DialogContent>
        </Dialog>
    );
}

function RefundDialog({
    open,
    onCancel,
    onRefund,
    productDetail,
}: {
    open: boolean;
    onCancel: () => void;
    onRefund: (payload: RefundPayload) => void;
    productDetail: ProductDetailsType;
}) {
    switch (productDetail.productType) {
        case ParkingaboProductType.SUBSCRIPTION:
            if (productDetail.bookingData === null) {
                return null;
            }
            return (
                <RefundDialogSubscription
                    open={open}
                    onCancel={onCancel}
                    onRefund={onRefund}
                    contractId={productDetail.data.contractId}
                    maxAmountRappen={
                        productDetail.refundData
                            ? productDetail.bookingData.totalAmountRappen -
                              productDetail.refundData.totalAmountRappen
                            : productDetail.bookingData.totalAmountRappen
                    }
                />
            );
        case ParkingaboProductType.RESERVATION:
        case ParkingaboProductType.GUEST_RESERVATION:
            return (
                <RefundDialogReservation
                    open={open}
                    onCancel={onCancel}
                    onRefund={onRefund}
                    contractId={productDetail.data.contractId}
                />
            );
        case ParkingaboProductType.PERMIT_UNTIL_REVOCATION:
        case ParkingaboProductType.PERMIT_FREE_FIXED_DURATION:
        case ParkingaboProductType.ALL_ACCESS_BARRIER_GATE:
            return null;
    }
}

function RefundDialogReservation({
    open,
    onCancel,
    onRefund,
    contractId,
}: {
    open: boolean;
    onCancel: () => void;
    onRefund: (payload: ReservationRefundPayload) => void;
    contractId: number;
}) {
    const [remark, setRemark] = useState('');

    function cancel() {
        setRemark('');
        onCancel();
    }

    return (
        <Dialog open={open} maxWidth="xs" onClose={cancel}>
            <DialogTitle>
                <Localized
                    de="Produkt zurückerstatten"
                    fr="Rembourser le produit"
                    it="Rimborsa prodotto"
                    en="Refund product"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}>
                    <Localized
                        de={
                            <>
                                Möchten Sie wirklich <b>den Gesamtpreis</b>{' '}
                                dieses Produkts zurückerstatten?
                            </>
                        }
                        fr={
                            <>
                                Voulez-vous vraiment rembourser{' '}
                                <b>le prix total</b> de ce produit?
                            </>
                        }
                        it={
                            <>
                                Vuole veramente rimborsare{' '}
                                <b>il prezzo totale</b> di questo prodotto?
                            </>
                        }
                        en={
                            <>
                                Do you really want to refund{' '}
                                <b>the full price</b> of this product?
                            </>
                        }
                    />
                </DialogContentText>
                <TextField
                    autoFocus
                    sx={{ marginBottom: 3 }}
                    label={
                        <Localized
                            de="Bemerkung"
                            fr="Remarque"
                            it="Osservazione"
                            en="Remark"
                        />
                    }
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                />
                <DialogConfirmCancelActions
                    onConfirm={() =>
                        onRefund({ contractId: contractId, remark: remark })
                    }
                    onCancel={cancel}
                    confirmDisabled={false}
                />
            </DialogContent>
        </Dialog>
    );
}

function RefundDialogSubscription({
    open,
    onCancel,
    onRefund,
    contractId,
    maxAmountRappen,
}: {
    open: boolean;
    onCancel: () => void;
    onRefund: (payload: SubscriptionRefundPayload) => void;
    contractId: number;
    maxAmountRappen: number;
}) {
    const [amount, setAmount] = useState('0.00');
    const [remark, setRemark] = useState('');
    const isError = Number(amount) * 100 > maxAmountRappen;

    function cancel() {
        setRemark('');
        setAmount('0.00');
        onCancel();
    }

    return (
        <Dialog open={open} maxWidth="xs" onClose={cancel}>
            <DialogTitle>
                <Localized
                    de="Rückerstattung"
                    fr="Remboursement"
                    it="Rimborso"
                    en="Refund"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ paddingBottom: 2 }}>
                    <Localized
                        de="Möchten Sie wirklich den folgenden Betrag zurückerstatten?"
                        fr="Voulez-vous vraiment rembourser le montant suivant?"
                        it="Desideri davvero rimborsare l'importo seguente?"
                        en="Do you really want to refund the following amount?"
                    />
                </DialogContentText>
                <TextField
                    autoFocus
                    sx={{ marginBottom: 3 }}
                    label={
                        <Localized
                            de="Betrag"
                            fr="Montant"
                            it="Importo"
                            en="Amount"
                        />
                    }
                    type="number"
                    error={isError}
                    helperText={
                        isError ? (
                            <Localized
                                de={`Der Wert übersteigt den Höchstbetrag (${currencyCentsToLocalPrice(
                                    'de',
                                    maxAmountRappen,
                                )}).`}
                                fr={`La valeur dépasse le montant maximum (${currencyCentsToLocalPrice(
                                    'fr',
                                    maxAmountRappen,
                                )}).`}
                                it={`Il valore supera l'importo massimo (${currencyCentsToLocalPrice(
                                    'it',
                                    maxAmountRappen,
                                )}).`}
                                en={`The value exceeds maximum amount (${currencyCentsToLocalPrice(
                                    'en',
                                    maxAmountRappen,
                                )}).`}
                            />
                        ) : null
                    }
                    InputProps={{
                        inputProps: { min: 0, max: maxAmountRappen / 100 },
                    }}
                    fullWidth
                    value={amount}
                    onChange={event =>
                        setAmount(validateRefundInput(event.target.value))
                    }
                />
                <TextField
                    autoFocus
                    sx={{ marginBottom: 3 }}
                    label={
                        <Localized
                            de="Bemerkung"
                            fr="Remarque"
                            it="Osservazione"
                            en="Remark"
                        />
                    }
                    type="text"
                    fullWidth
                    value={remark}
                    onChange={event => setRemark(event.target.value)}
                />
                <DialogConfirmCancelActions
                    onConfirm={() =>
                        onRefund({
                            contractId: contractId,
                            remark: remark,
                            subscriptionRefundAmountRappen:
                                Number(amount) * 100,
                        })
                    }
                    onCancel={cancel}
                    confirmDisabled={isError}
                />
            </DialogContent>
        </Dialog>
    );

    function validateRefundInput(value: string) {
        const m = value.match(/(\d+)(([.,])(\d{0,2})?)?/);
        if (!m) {
            return '';
        } else {
            return (
                m[1] + (m[3] || m[4] ? '.' + getOrElse<string>(m[4], '') : '')
            );
        }
    }
}

function DialogConfirmCancelActions({
    onConfirm,
    onCancel,
    confirmDisabled,
}: {
    onConfirm: () => void;
    onCancel: () => void;
    confirmDisabled: boolean;
}) {
    return (
        <DialogActions>
            <Button onClick={onCancel}>
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            </Button>
            <Button onClick={onConfirm} disabled={confirmDisabled}>
                <Localized
                    de="Bestätigen"
                    fr="Confirmer"
                    it="Conferma"
                    en="Confirm"
                />
            </Button>
        </DialogActions>
    );
}

export function MissingPermissionsText() {
    return (
        <Localized
            de="Funktion nicht verfügbar, da Ihnen die erforderlichen Berechtigungen fehlen"
            fr="Fonction non disponible car vous ne disposez pas des droits nécessaires"
            it="Funzione non disponibile perché le mancano i diritti necessari"
            en="Function not available because you do not have the necessary permissions"
        />
    );
}

function StornoTooltip({
    isStornoBlocked,
}: {
    isStornoBlocked: StornoBlockedReason;
}): JSX.Element {
    switch (isStornoBlocked) {
        case StornoBlockedReason.MISSING_PERMISSIONS:
            return <MissingPermissionsText />;
        case StornoBlockedReason.ALREADY_PURCHASED:
            return (
                <Localized
                    de="Funktion nur für noch nicht belastete Produkte verfügbar"
                    fr="Fonction uniquement disponible pour produits pas encore débités"
                    it="Funzione disponibile solo per prodotti non ancora addebitati"
                    en="Function only available for products not yet debited"
                />
            );
        case StornoBlockedReason.WRONG_PRODUCT_STATE:
            return (
                <Localized
                    de="Funktion nur für in der Zukunft gültige Produkte verfügbar"
                    fr="Fonction disponible uniquement pour produits valides dans le futur"
                    it="Funzione disponibile solo per prodotti validi in futuro"
                    en="Function only available for products valid in the future"
                />
            );
    }
}

function ReactivateTooltip({
    isReactivateBlocked,
}: {
    isReactivateBlocked: ReactivateBlockedReason;
}): JSX.Element {
    switch (isReactivateBlocked) {
        case ReactivateBlockedReason.MISSING_PERMISSIONS:
            return <MissingPermissionsText />;
        case ReactivateBlockedReason.WRONG_PRODUCT_TYPE:
            return (
                <Localized
                    de="Funktion nicht verfügbar für diesen Produkttyp"
                    fr="Fonction non disponible pour ce type de produit"
                    it="Funzione non disponibile per questo tipo di prodotto"
                    en="Function not available for this product type"
                />
            );
        case ReactivateBlockedReason.WRONG_PRODUCT_STATE:
            return (
                <Localized
                    de="Funktion nur für aktiv gültige Abonnoments mit Enddatum verfügbar"
                    fr="Fonctionnalité disponible uniquement pour les abonnements actifs avec une date de fin"
                    it="Funzionalità disponibile solo per gli abbonamenti attivi con una data di fine"
                    en="Function only available for active subscriptions with an end date"
                />
            );
        case ReactivateBlockedReason.NOT_YET_TERMINATED:
            return (
                <Localized
                    de="Für dieses Produkt wurde noch kein Enddatum der Gültigkeit festgelegt und es kann daher nicht reaktiviert werden"
                    fr="La date limite de validité n'a pas encore été définie pour ce produit et il ne peut donc pas être réactivé"
                    it="Per questo prodotto non è ancora stata definita una data finale di validità e quindi non può essere riattivato"
                    en="This product has not yet been set an end date of validity and therefore cannot be reactivated"
                />
            );
        case ReactivateBlockedReason.PAST_END_DATE:
            return (
                <Localized
                    de="Das Enddatum der Gültigkeit ist bereits erreicht und daher kann das Produkt nicht mehr reaktiviert werden"
                    fr="La date limite de validité a déjà été atteinte et le produit ne peut donc plus être réactivé"
                    it="La data finale di validità è già stata raggiunta e quindi il prodotto non può più essere riattivato"
                    en="The end date of validity has already been reached and therefore the product can no longer be reactivated"
                />
            );
    }
}

function TerminateRefundTooltip({
    blockedReason,
    productType,
    type,
}: {
    blockedReason: RefundBlockedReason;
    productType: ParkingaboProductType;
    type: 'terminate' | 'refund';
}): JSX.Element {
    switch (blockedReason) {
        case RefundBlockedReason.MISSING_PERMISSIONS:
            return <MissingPermissionsText />;
        case RefundBlockedReason.ALREADY_REFUNDED:
            return (
                <Localized
                    de="Produkt bereits zurückbezahlt"
                    fr="Produit déjà remboursé"
                    it="Prodotto già rimborsato"
                    en="Product already refunded"
                />
            );
        case RefundBlockedReason.WRONG_PRODUCT_TYPE:
            return (
                <Localized
                    de="Funktion nicht verfügbar für diesen Produkttyp"
                    fr="Fonction non disponible pour ce type de produit"
                    it="Funzione non disponibile per questo tipo di prodotto"
                    en="Function not available for this product type"
                />
            );
        case RefundBlockedReason.NO_ACTIVE_ACCOUNT_TO_REFUND:
            return (
                <Localized
                    de="Rückzahlung nicht möglich: Benutzerkonto gesperrt"
                    fr="Remboursement impossible: compte utilisateur bloqué"
                    it="Rimborso non possibile: conto utente bloccato"
                    en="Refund not possible: user account blocked"
                />
            );
        case RefundBlockedReason.NON_POSITIVE_PRICE:
            return (
                <Localized
                    de="Rückzahlung nicht möglich: Gratisprodukt"
                    fr="Remboursement impossible: produit gratuit"
                    it="Rimborso non possibile: prodotto gratuito"
                    en="Refund not possible: free product"
                />
            );
        case RefundBlockedReason.NO_DIGITAL_PURCHASE:
            return (
                <Localized
                    de="Rückzahlung nicht möglich: es gibt keine Online-Belastung"
                    fr="Remboursement impossible: il n'y a aucun débit en ligne"
                    it="Rimborso non possibile: non esiste alcun addebito online"
                    en="Refund not possible: there is no online charge"
                />
            );
        case RefundBlockedReason.NO_SUBSCRIPTION_CHARGE:
            return (
                <Localized
                    de="Es gibt noch keine Belastungen. Daher ist keine Erstattung mehr möglich."
                    fr="Aucun débit n'a encore été effectué. Aucun remboursement n'est donc possible."
                    it="Non sono stati ancora effettuati addebiti. Pertanto nessun rimborso è possibile."
                    en="There have not been any charges yet. Therefore no refund is possible."
                />
            );
        case RefundBlockedReason.PAST_REFUND_TIME:
            switch (productType) {
                case ParkingaboProductType.RESERVATION:
                    return (
                        <Localized
                            de="Rückzahlung nicht mehr möglich: Produkt älter als 3 Monate"
                            fr="Remboursement n'est plus possible: produit datant de plus de 3 mois"
                            it="Rimborso non più possibile: prodotto più vecchio di 3 mesi"
                            en="Refund no longer possible: product older than 3 months"
                        />
                    );
                case ParkingaboProductType.SUBSCRIPTION:
                    return (
                        <Localized
                            de="Rückzahlung nicht mehr möglich: Produkt älter als 6 Monate"
                            fr="Remboursement n'est plus possible: produit datant de plus de 6 mois"
                            it="Rimborso non più possibile: prodotto più vecchio di 6 mesi"
                            en="Refund no longer possible: product older than 6 months"
                        />
                    );
            }
            throw new Error(
                'Wrong product type should be the refund blocked reason',
            );
        case RefundBlockedReason.WRONG_PRODUCT_STATE:
            switch (type) {
                case 'terminate':
                    return (
                        <Localized
                            de="Funktion nur für aktuell gültige Produkte verfügbar"
                            fr="Fonction disponible uniquement pour les produits actuellement valides"
                            it="Funzione disponibile solo per prodotti attualmente validi"
                            en="Function only available for current valid products"
                        />
                    );
                case 'refund':
                    switch (productType) {
                        case ParkingaboProductType.RESERVATION:
                            return (
                                <Localized
                                    de="Funktion nur für abgelaufene Produkte verfügbar"
                                    fr="Fonction disponible uniquement pour les produits expirés"
                                    it="Funzione disponibile solo per prodotti scaduti"
                                    en="Function only available for expired products"
                                />
                            );
                        case ParkingaboProductType.SUBSCRIPTION:
                            return (
                                <Localized
                                    de="Funktion nur für gültige oder abgelaufene Produkte verfügbar"
                                    fr="Fonction disponible uniquement pour les produits valide ou expirés"
                                    it="Funzione disponibile solo per prodotti valido o scaduti"
                                    en="Function only available for valid or expired products"
                                />
                            );
                    }
                    throw new Error(
                        'Wrong product type should be the refund blocked reason',
                    );
            }
    }
}
