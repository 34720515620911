import { MainContent } from '../../shared-mui-components/MainContent.tsx';
import { Localized } from '../../common/components/Localized.tsx';
import { OperatorAsyncLoadedSection } from '../../app/components/OperatorAsyncLoadedSection.tsx';
import { LoadingSpinnerPresets } from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { EmptyResults } from '../../shared-mui-components/ListMessages.tsx';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { AccountBalanceWallet, CloudDownload } from '@mui/icons-material';
import {
    RequestStatus,
    useServerFetch,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { SeparationBar } from '../../layout/components/SeparationBar.tsx';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { Language } from 'dg-web-shared/lib/Text.ts';
import { useOperatorLanguage } from '../../common/state/SettingsState.ts';
import { Formatter } from 'dg-web-shared/lib/Date.ts';
import { PeripheryDisplayName } from '../CloudConnectorPeripheriesOverview.tsx';
import { CloudConnectorPeripheryType } from 'dg-web-shared/model/CloudConnector.ts';
import { HeaderWithButtons } from '../../shared-mui-components/HeaderWithButtons.tsx';

interface PayStationCollectionData {
    collectionNr: string;
    collectedAt: string;
    zoneName: string;
    payStationName: string | null;
    onPremiseId: number;
    transactionsCount: number;
    collectedAmountRappen: number;
}

export function PayStationCollections() {
    const language = useOperatorLanguage();
    const [payStationCollectionState] = useServerFetch<
        PayStationCollectionData[],
        object
    >(
        () => ({
            url: `/ui-api/operator-account/pay-station/collections`,
        }),
        {},
    );

    return (
        <MainContent>
            <HeaderWithButtons
                icon={AccountBalanceWallet}
                title={
                    <Localized
                        de="Kassen-Leerungen"
                        fr="Vidages des caisses"
                        it="Vuotature casse"
                        en="Pay stations colletions"
                    />
                }
                headerButtons={
                    <Button
                        variant="outlined"
                        startIcon={<CloudDownload />}
                        sx={{
                            marginLeft: 3,
                            marginRight: 3,
                        }}
                        href={`/ui-api/operator-account/pay-station/collections/pdf`}
                        target="_blank"
                        rel="noreferrer"
                        disabled={
                            payStationCollectionState.status ===
                                RequestStatus.PENDING ||
                            payStationCollectionState.status ===
                                RequestStatus.ERROR ||
                            (payStationCollectionState.status ===
                                RequestStatus.SUCCESS &&
                                payStationCollectionState.data.length < 1)
                        }
                    >
                        <Localized
                            de="Exportieren"
                            fr="Exporter"
                            it="Esporta"
                            en="Export"
                        />
                    </Button>
                }
            />
            <SeparationBar />
            <OperatorAsyncLoadedSection
                requestState={payStationCollectionState}
                pendingLoaderPreset={
                    LoadingSpinnerPresets.FillAllSpaceAndCenter
                }
                render={data => {
                    if (data.length === 0) {
                        return <EmptyResults hasFilter={false} />;
                    }
                    return (
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Localized
                                                de="Leerungs Nr."
                                                fr="No. vidange"
                                                it="Nr. vuotatura"
                                                en="Collection no."
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Datum"
                                                fr="Date"
                                                it="Data"
                                                en="Date"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Parking"
                                                fr="Parking"
                                                it="Parcheggio"
                                                en="Parking"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Kasse"
                                                fr="Caisse"
                                                it="Cassa"
                                                en="Pay station"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Anzahl Transaktionen"
                                                fr="Nombre de transactions"
                                                it="Numero di transazioni"
                                                en="Number of transactions"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Localized
                                                de="Betrag (CHF)"
                                                fr="Montant (CHF)"
                                                it="Importo (CHF)"
                                                en="Amount (CHF)"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(collection => (
                                        <PayStationStatementRow
                                            key={collection.collectionNr}
                                            language={language}
                                            collection={collection}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            />
        </MainContent>
    );
}

function PayStationStatementRow({
    language,
    collection,
}: {
    language: Language;
    collection: PayStationCollectionData;
}) {
    return (
        <TableRow>
            <TableCell>{collection.collectionNr}</TableCell>
            <TableCell>
                {Formatter.dayMonthYearHourMinuteSecond(collection.collectedAt)}
            </TableCell>
            <TableCell>{collection.zoneName}</TableCell>
            <TableCell>
                <PeripheryDisplayName
                    peripheryType={CloudConnectorPeripheryType.PAY_STATION}
                    name={collection.payStationName}
                    onPremiseId={collection.onPremiseId}
                />
            </TableCell>
            <TableCell>{collection.transactionsCount}</TableCell>
            <TableCell>
                {currencyCentsToLocalPrice(
                    language,
                    collection.collectedAmountRappen,
                )}
            </TableCell>
        </TableRow>
    );
}
