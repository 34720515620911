import { Logout } from '@mui/icons-material';
import { ParkingPortalLayoutWithHeader } from './layout/ParkingPortalLayoutWithHeader.tsx';
import { ParkingPortalMenuListItem } from './layout/ParkingPortalMenuListItem.tsx';
import * as AuthenticationActions from '../app/actions/AuthenticationActions.ts';
import { useUpdate } from 'dg-web-shared/lib/Flux.tsx';
import { Localized } from '../common/components/Localized.tsx';
import { useOperatorContext } from '../app/components/BaseLayoutAndData.tsx';
import { Box } from '@mui/material';
import { ParkingPortalFooter } from './layout/ParkingPortalFooter.tsx';
import { ReadOnlyTextField } from '../ui/material-ui/ReadOnlyTextField.tsx';
import { getBridge } from '../app/components/Native.tsx';

export function ParkingPortalSettings() {
    const update = useUpdate();
    const { currentLogin } = useOperatorContext();
    return (
        <ParkingPortalLayoutWithHeader
            title={
                <Localized
                    de="Einstellungen"
                    fr="Paramètres"
                    it="Impostazioni"
                    en="Settings"
                />
            }
            backTo={'..'}
        >
            <Box
                sx={{
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={theme => ({
                        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
                        minHeight: 80,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '24px',
                        paddingTop: '12px',
                    })}
                >
                    <ReadOnlyTextField
                        label={
                            <Localized
                                de="Benutzer"
                                fr="Utilisateur"
                                it="Utente"
                                en="User"
                            />
                        }
                        value={`${currentLogin.surname} ${currentLogin.lastname}`}
                    />
                </Box>
                {currentLogin.permissions.cloudConnectorAlerts &&
                    currentLogin.fcmToken !== null && (
                        <ParkingPortalMenuListItem
                            text={
                                <Localized
                                    de={`Push notification settings`}
                                    fr={`Push notification settings`}
                                    it={`Push notification settings`}
                                    en={`Push notification settings`}
                                />
                            }
                            onClick={() => {
                                const bridge = getBridge();
                                if (bridge) {
                                    bridge.notificationSettings();
                                }
                            }}
                        />
                    )}
                <ParkingPortalMenuListItem
                    onClick={() => update(AuthenticationActions.logout)}
                    text={
                        <Localized
                            de="Abmelden"
                            fr="Déconnecter"
                            it="Esci"
                            en="Logout"
                        />
                    }
                    icons={[<Logout key={0} color={'primary'} />]}
                    hideChevron
                />
            </Box>
            <ParkingPortalFooter />
        </ParkingPortalLayoutWithHeader>
    );
}
