import * as CurrentOperatorLoginState from '../../common/state/CurrentOperatorLoginState.ts';
import * as OperatorDataState from '../../common/state/OperatorDataState.ts';
import { OperatorAppRoutes } from '../config/OperatorRoutingConfig.tsx';

function every(...args: boolean[]): boolean {
    return args.every(arg => arg);
}

function routeModuleActive(
    route: OperatorAppRoutes,
    operatorData: OperatorDataState.OperatorData,
): boolean {
    const type = getRouteType(route);
    switch (type) {
        case OperatorRouteType.Default:
            return true;
        case OperatorRouteType.ParkingPay:
        case OperatorRouteType.Parkingabo:
            return operatorData.hasParkingpayModule;
        case OperatorRouteType.Onstreet:
            return (
                operatorData.hasOnstreetTransactions ||
                operatorData.hasTaxomexModule ||
                operatorData.hasEnforcement ||
                operatorData.hasActiveOnstreetZone
            );
        case OperatorRouteType.Offstreet:
            return operatorData.existingOffstreetTransactionsType != null;
        case OperatorRouteType.External:
            return (
                operatorData.parkomaticSmartfolio ||
                operatorData.taxomexMiofficeIframe ||
                operatorData.taxomexParkMonitorIframe
            );
    }
}

export function isRouteAllowed(
    operatorData: OperatorDataState.OperatorData,
    login: CurrentOperatorLoginState.CurrentOperatorLogin,
    route: OperatorAppRoutes,
): boolean {
    if (operatorData.enforcementOnly) {
        return [
            OperatorAppRoutes.Home,
            OperatorAppRoutes.Logins,
            OperatorAppRoutes.ControlHistory,
        ].includes(route);
    }

    const permissions = login.permissions;

    if (!permissions.operatorAppActive) {
        return false;
    }

    if (!routeModuleActive(route, operatorData)) {
        return false;
    }

    switch (route) {
        case OperatorAppRoutes.ClearancesPermitsList:
            return (
                operatorData.licensePlatePermitSettings.active &&
                (permissions.permitsRead || permissions.whitelistCreate)
            );
        case OperatorAppRoutes.ClearanceRequests:
            return every(
                operatorData.licensePlatePermitSettings.hasClearanceRequests,
                permissions.whitelistCreate,
            );
        case OperatorAppRoutes.ActivityStatement:
            return every(
                operatorData.settings.activityStatement,
                permissions.activityStatement,
            );
        case OperatorAppRoutes.OnstreetTransactions:
            return every(
                operatorData.hasOnstreetTransactions,
                permissions.transactions,
            );
        case OperatorAppRoutes.Statistics:
            return permissions.transactions;
        case OperatorAppRoutes.CustomerAccounts:
            return (
                operatorData.hasAWhitelistCustomerPermittype ||
                operatorData.hasAPermittypeCustomerGrant
            );
        case OperatorAppRoutes.SpecialReports:
            return operatorData.licensePlatePermitSettings
                .hasMobilityStatisticExport;
        case OperatorAppRoutes.ControlHistory:
            return every(
                operatorData.hasEnforcement,
                permissions.enforcementLog,
            );
        case OperatorAppRoutes.TomTransactions:
            return operatorData.hasTaxomexModule && permissions.transactions;
        case OperatorAppRoutes.ParkingMeters:
        case OperatorAppRoutes.UsbSticks:
            return operatorData.hasTaxomexModule;
        case OperatorAppRoutes.OffstreetTransactions:
            return every(
                permissions.transactions,
                operatorData.existingOffstreetTransactionsType !== null,
            );
        case OperatorAppRoutes.Parkmonitor:
            return operatorData.taxomexParkMonitorIframe;
        case OperatorAppRoutes.MiOffice:
            return operatorData.taxomexMiofficeIframe;
        case OperatorAppRoutes.ParkingaboUserCategories:
            return operatorData.numberOfActiveTenants > 1;
        case OperatorAppRoutes.Zones:
            return operatorData.hasActiveOnstreetZone;
        case OperatorAppRoutes.MobileHomePage:
        case OperatorAppRoutes.MobileCloudConnectorSettings:
            return operatorData.hasCloudConnector;
        case OperatorAppRoutes.CloudConnectorPeripheries:
        case OperatorAppRoutes.MobileCloudConnectorPeripheries:
        case OperatorAppRoutes.CloudConnectorPeripheriesDetail:
            return (
                operatorData.hasCloudConnector &&
                permissions.cloudConnectorOffstreetDevicesView
            );
        case OperatorAppRoutes.CloudConnectorRecode:
        case OperatorAppRoutes.CloudConnectorRecodeSuccessResponse:
        case OperatorAppRoutes.CloudConnectorRecodeErrorResponse:
        case OperatorAppRoutes.CloudConnectorRecodeTicket:
        case OperatorAppRoutes.CloudConnectorRecodeTicketCondition:
            return (
                operatorData.hasCloudConnector &&
                permissions.cloudConnectorRecodeTicket
            );
        case OperatorAppRoutes.PayStationStatement:
        case OperatorAppRoutes.PayStationCollections:
            return (
                operatorData.hasCloudConnector &&
                permissions.cloudConnectorReportingAccess
            );
        case OperatorAppRoutes.PeripheryHistory:
        case OperatorAppRoutes.PermissionTags:
        case OperatorAppRoutes.Home:
        case OperatorAppRoutes.Parkings:
        case OperatorAppRoutes.Logins:
        case OperatorAppRoutes.PermitTypes:
        case OperatorAppRoutes.Tariffs:
        case OperatorAppRoutes.ParkingaboUsers:
        case OperatorAppRoutes.ParkingaboUsersAdd:
        case OperatorAppRoutes.ParkingaboUsersInvite:
        case OperatorAppRoutes.ParkingaboUsersDetail:
        case OperatorAppRoutes.ParkingaboUsersProducts:
        case OperatorAppRoutes.ParkingaboUsersProductsAdd:
        case OperatorAppRoutes.ParkingaboUsersProductsDetail:
        case OperatorAppRoutes.ParkingaboUsersVehicles:
        case OperatorAppRoutes.ParkingaboUsersVehiclesAdd:
        case OperatorAppRoutes.ParkingaboUsersVehiclesDetail:
        case OperatorAppRoutes.ParkingaboUsersAccountStatement:
        case OperatorAppRoutes.OffstreetTransactionsTicketDetail:
        case OperatorAppRoutes.OffstreetTransactionsEntryWithoutExitDetail:
        case OperatorAppRoutes.OffstreetTransactionsContractDetail:
        case OperatorAppRoutes.ParkingaboProducts:
        case OperatorAppRoutes.ParkingaboProductsDetail:
        case OperatorAppRoutes.ParkingaboProductTypes:
        case OperatorAppRoutes.ParkingaboProductTypesDetail:
        case OperatorAppRoutes.ParkingaboQuotasDetail:
        case OperatorAppRoutes.Privacy:
            return true;
    }
}

export enum OperatorRouteType {
    Default,
    Onstreet,
    Offstreet,
    ParkingPay,
    Parkingabo,
    External,
}

export function getRouteType(route: OperatorAppRoutes): OperatorRouteType {
    switch (route) {
        case OperatorAppRoutes.Home:
        case OperatorAppRoutes.Tariffs:
        case OperatorAppRoutes.Logins:
        case OperatorAppRoutes.Statistics:
        case OperatorAppRoutes.ActivityStatement:
        case OperatorAppRoutes.ParkingaboQuotasDetail:
        case OperatorAppRoutes.MobileHomePage:
        case OperatorAppRoutes.Privacy:
            return OperatorRouteType.Default;

        case OperatorAppRoutes.OnstreetTransactions:
        case OperatorAppRoutes.TomTransactions:
        case OperatorAppRoutes.ControlHistory:
        case OperatorAppRoutes.Zones:
        case OperatorAppRoutes.ParkingMeters:
        case OperatorAppRoutes.UsbSticks:
        case OperatorAppRoutes.PermissionTags:
            return OperatorRouteType.Onstreet;

        case OperatorAppRoutes.OffstreetTransactions:
        case OperatorAppRoutes.OffstreetTransactionsTicketDetail:
        case OperatorAppRoutes.OffstreetTransactionsEntryWithoutExitDetail:
        case OperatorAppRoutes.OffstreetTransactionsContractDetail:
        case OperatorAppRoutes.Parkings:
        case OperatorAppRoutes.CloudConnectorPeripheries:
        case OperatorAppRoutes.CloudConnectorPeripheriesDetail:
        case OperatorAppRoutes.CloudConnectorRecode:
        case OperatorAppRoutes.CloudConnectorRecodeSuccessResponse:
        case OperatorAppRoutes.CloudConnectorRecodeErrorResponse:
        case OperatorAppRoutes.CloudConnectorRecodeTicket:
        case OperatorAppRoutes.CloudConnectorRecodeTicketCondition:
        case OperatorAppRoutes.MobileCloudConnectorPeripheries:
        case OperatorAppRoutes.MobileCloudConnectorSettings:
        case OperatorAppRoutes.PayStationStatement:
        case OperatorAppRoutes.PayStationCollections:
        case OperatorAppRoutes.PeripheryHistory:
            return OperatorRouteType.Offstreet;

        case OperatorAppRoutes.ClearancesPermitsList:
        case OperatorAppRoutes.ClearanceRequests:
        case OperatorAppRoutes.CustomerAccounts:
        case OperatorAppRoutes.PermitTypes:
        case OperatorAppRoutes.SpecialReports:
            return OperatorRouteType.ParkingPay;

        case OperatorAppRoutes.ParkingaboUsers:
        case OperatorAppRoutes.ParkingaboUsersAdd:
        case OperatorAppRoutes.ParkingaboUsersInvite:
        case OperatorAppRoutes.ParkingaboUsersDetail:
        case OperatorAppRoutes.ParkingaboUsersProducts:
        case OperatorAppRoutes.ParkingaboUsersProductsAdd:
        case OperatorAppRoutes.ParkingaboUsersProductsDetail:
        case OperatorAppRoutes.ParkingaboUsersVehicles:
        case OperatorAppRoutes.ParkingaboUsersVehiclesAdd:
        case OperatorAppRoutes.ParkingaboUsersVehiclesDetail:
        case OperatorAppRoutes.ParkingaboUsersAccountStatement:
        case OperatorAppRoutes.ParkingaboProducts:
        case OperatorAppRoutes.ParkingaboProductsDetail:
        case OperatorAppRoutes.ParkingaboProductTypes:
        case OperatorAppRoutes.ParkingaboProductTypesDetail:
        case OperatorAppRoutes.ParkingaboUserCategories:
            return OperatorRouteType.Parkingabo;

        case OperatorAppRoutes.Parkmonitor:
        case OperatorAppRoutes.MiOffice:
            return OperatorRouteType.External;
    }
}
